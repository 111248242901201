import { useState, useEffect } from 'react';
import { Grid, GridItem, Image, Flex } from '@chakra-ui/react';
import LogOutButton from '../../atoms/LogOutButton/LogOutButton';
import { fetchToggles } from '../../../services/FirebaseRemoteConfig';
import ButtonWithLeftIcon from '../../atoms/ButtonWithLeftIcon';
import { DownloadIcon } from '@chakra-ui/icons';
import ButtonDownloadReport from '../ButtonDownloadReport';

const HeaderComponent = ({
  isVoluntarie = true,
  showDownloadButtons = false,
}) => {
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    isVoluntarie &&
      showDownloadButtons &&
      fetchToggles().then((res) => {
        const showDownloadButton = res?.REACT_APP_SHOW_DOWNLOAD_DATA_BUTTON;
        if (!showDownloadButton) return;
        setShowButtons(showDownloadButton.asBoolean());
      });
  }, []);

  return (
    <Grid
      w="100%"
      h="100%"
      borderBottom="1px"
      borderColor="carbon.200"
      templateColumns={'80px 1fr 1fr'}
      alignItems="center"
      p="0 24px"
    >
      <GridItem margin={'initial'}>
        <Image
          src={'/images/logo-todo-mejora-icon.svg'}
          w="52px"
          h="auto"
        />
      </GridItem>

      <Flex
        flexDirection="row"
        gap={4}
      >
        {showButtons && <ButtonDownloadReport />}
        {showButtons && (
          <ButtonWithLeftIcon
            datatestid="download-material-button"
            text={'Descargar material de apoyo'}
            iconSource={<DownloadIcon />}
          />
        )}
      </Flex>
      <GridItem textAlign="right">
        <LogOutButton />
      </GridItem>
    </Grid>
  );
};
export default HeaderComponent;
