import { Input } from '@chakra-ui/react';

const InputPhoneNumber = (props) => {
  return (
    <Input
      borderColor="#7A57B2"
      focusBorderColor="#00AEA5"
      errorBorderColor="#D6075B"
      id="phoneInput"
      data-testid="phoneInput"
      type="phone"
      w="15.125rem"
      placeholder=""
      {...props}
    />
  );
};

export default InputPhoneNumber;
