import { Icon } from '@chakra-ui/react';

const UsuarieCircleStatus = ({ status }) => {
  const colors = {
    inactive: 'heart.500',
    active: 'leaf.500',
    absent: 'golden.500',
  };

  return (
    <>
      <Icon
        viewBox="0 0 20 20"
        mx="10px"
        boxSize={4}
        data-testid="colorCircle"
        fill={colors[status]}
      >
        <circle
          cx={10}
          cy={10}
          r={10}
        />
      </Icon>
    </>
  );
};
export default UsuarieCircleStatus;
