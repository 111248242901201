import { Box, useRadio } from '@chakra-ui/react';

function ChipButton({ ...props }) {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...props}
        {...checkbox}
        sx={{
          borderRadius: '4px',
          border: '1px solid #737373',
          color: 'carbon.400',
          minHeight: '46px',
          fontSize: '14px',
          fontWeight: '400',
          height: '46px',
          lineHeight: '18px',
          textAlign: 'center',
        }}
        _checked={{
          bgColor: 'mora.500',
          fontSize: '14px',
          color: 'white',
          textStyle: 'body1',
          borderColor: 'mora.500',
          borderRadius: '4px',
          textAlign: 'center',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default ChipButton;
