import { Box, Input, Text } from '@chakra-ui/react';
const FormLabelInput = ({ boxProps, inputProps, labelText, onChange }) => {
  return (
    <Box {...boxProps}>
      <Text
        color="mora.500"
        textStyle="h5"
        fontSize="14px"
      >
        {labelText} *
      </Text>
      <Input
        mt="0.5rem"
        borderColor="mora.500"
        opacity="0.8"
        _focus={{ borderColor: 'aqua.500' }}
        onChange={onChange}
        {...inputProps}
      />
    </Box>
  );
};

export default FormLabelInput;
