const ERROR_CATALOG = {
  VOLUNTARIES: {
    GET_MESSAGES: 'getMessages (in voluntarie)',
    SEND_MESSAGE: 'sendMessage (in voluntarie)',
    GET_ROOMS: 'getRooms (in voluntarie)',
    CLOSING_CHAT: 'Closing chat (in voluntarie)',
    SENDING_PHONE_CODE: 'Sending phone code (in voluntarie)',
    DOWNLOAD_DATA: 'Downloading report',
  },
  USUARIES: {
    GET_MESSAGES: 'getMessages (in usuarie)',
    SEND_MESSAGE: 'sendMessage (in usuarie)',
    SEND_CODE: 'Sending phone code',
    VERIFY_CODE: 'Verify phone code',
  },
};

export default ERROR_CATALOG;
