import { createSlice } from '@reduxjs/toolkit';

const initialMessagesState = { messages: [] };
const messageSlice = createSlice({
  name: 'messages',
  initialState: initialMessagesState,
  reducers: {
    setMessages(state, action) {
      state.messages = action.payload;
    },
    addMessage(state, action) {
      state.messages.push(action.payload);
    },
  },
});

export const messagesActions = messageSlice.actions;
export const messagesReducers = messageSlice.reducer;
export default messageSlice;
