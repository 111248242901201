import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { RenderRecaptcha, sendPhoneCode } from '../../utils/recaptcha';
import { CL } from '../../utils/countryPhoneCode';
import RecaptchaRender from '../atoms/RecaptchaRender';
import PhoneValidation from '../organisms/usuaries/PhoneValidation';
import { Container } from '@chakra-ui/react';
import { pinSentActions } from '../../store/Slices/pinSent';
import { logErrorEvent } from '../../helpers/logAnalytics';
import ERROR_CATALOG from '../../helpers/errorCatalog';

const WithRecaptchaHandleV2 = (Component) => {
  const dispatch = useDispatch();
  const pinSent = useSelector((state) => state.pinSent.isPinSent);
  const phoneNumber = useSelector((state) => state.phoneNumber.number);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [result, setResult] = useState('');
  const [showRecaptcha, setShowRecaptcha] = useState(true);
  const recaptchaReady = useRef(false);

  const handleOnClick = (e) => {
    e.preventDefault();
    sendPhoneCode(CL, phoneNumber, recaptchaVerifier)
      .then((response) => {
        dispatch(pinSentActions.setPinSent());
        setResult(response);
        setShowRecaptcha(false);
      })
      .catch((error) => {
        console.error(error);
        logErrorEvent({
          message: error.message,
          component_name: ERROR_CATALOG.USUARIES.SEND_CODE,
        });
        setShowRecaptcha(true);
      });
  };

  useEffect(() => {
    setShowRecaptcha(true);
  }, [phoneNumber]);

  useEffect(() => {
    if (recaptchaReady.current) return;
    const verifier = RenderRecaptcha();
    verifier.render().then(() => {
      setRecaptchaVerifier(verifier);
    });
    recaptchaReady.current = true;
  }, []);

  return (
    <>
      <RecaptchaRender showCaptcha={showRecaptcha} />
      <Component onClick={handleOnClick} />
      <Container style={{ display: pinSent ? 'block' : 'none' }}>
        <PhoneValidation
          result={result}
          userPhone={phoneNumber}
        />
      </Container>
    </>
  );
};

export default WithRecaptchaHandleV2;
