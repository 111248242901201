import { createSlice } from '@reduxjs/toolkit';

const initialPhoneState = { isValid: false, number: '' };
const phoneNumberSlice = createSlice({
  name: 'phoneNumber',
  initialState: initialPhoneState,
  reducers: {
    setValidPhoneNumber(state, action) {
      state.isValid = true;
      state.number = action.payload;
    },
    setInvalidPhoneNumber(state, action) {
      state.isValid = false;
      state.number = action.payload;
    },
    setPhoneNumber(state, action) {
      state.number = action.payload;
    },
  },
});

export const phoneNumberActions = phoneNumberSlice.actions;
export const phoneNumberReducers = phoneNumberSlice.reducer;
export default phoneNumberSlice;
