import { Box, Image, Text } from '@chakra-ui/react';

const VoluntarieGreetingMessage = () => {
  return (
    <Box
      align="center"
      flexDirection="column"
      width="75%"
      justifyContent="center"
      alignItems="center"
      display="flex"
      borderLeft="1px solid"
      borderColor="carbon.100"
    >
      <Image
        src={'/images/family-support.svg'}
        w={'26.063rem'}
        h={'21.563rem'}
        filter="grayscale(100%)"
      />
      <Text
        textStyle="h2"
        textAlign="center"
        w="377px"
      >
        Bienvenide a La Hora Segura. Gracias por ser parte de este programa.
      </Text>
    </Box>
  );
};
export default VoluntarieGreetingMessage;
