import { Text } from '@chakra-ui/react';
import ContainerCenterContent from './ContainerCenterContent';

const PimFormTitle = () => {
  return (
    <ContainerCenterContent
      maxW="md"
      mt="1.75rem"
    >
      <Text
        color="mora.500"
        lineHeight="2.125rem"
        textStyle="h2"
      >
        Ingresa el código
      </Text>
    </ContainerCenterContent>
  );
};

export default PimFormTitle;
