import { createSlice } from '@reduxjs/toolkit';

const initialRoomState = { currentRoom: ['0000x', { name: '', uuid: '' }] };

const roomSlice = createSlice({
  name: 'room',
  uuid: 'roomUUID',
  initialState: initialRoomState,
  reducers: {
    setRoom(state, action) {
      state.currentRoom = action.payload;
    },
  },
});
export const roomActions = roomSlice.actions;
export const roomReducers = roomSlice.reducer;
export default roomSlice;
