import { Container, Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const BACKGROUND_AS_VALID = 'mora.500';
const BACKGROUND_AS_INVALID = 'carbon.300';

const ButtonSendPhoneCode = ({ onClick }) => {
  const validPhoneNumber = useSelector((state) => state.phoneNumber.isValid);
  const pinSent = useSelector((state) => state.pinSent.isPinSent);
  const handleButtonColor = () => {
    if (validPhoneNumber) {
      return BACKGROUND_AS_VALID;
    } else {
      return BACKGROUND_AS_INVALID;
    }
  };

  return (
    <Container
      alignContent="center"
      textAlign="center"
      mt="1.25rem"
      centerContent
    >
      <Button
        data-testid="sendPhoneButton"
        w="20rem"
        h="2.875rem"
        variant="primary"
        bgColor={handleButtonColor}
        isDisabled={!validPhoneNumber || pinSent}
        onClick={onClick}
      >
        Enviar código
      </Button>
    </Container>
  );
};

export default ButtonSendPhoneCode;
