import { Image } from '@chakra-ui/react';
const IconApp = () => {
  return (
    <Image
      src={'/images/logo-todo-mejora-icon.svg'}
      alt={'logo corazon todo mejora'}
      boxSize={'62px'}
    />
  );
};

export default IconApp;
