import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchToggles } from '../../../services/FirebaseRemoteConfig';
import useRequestMessages from '../../../hooks/useRequestMessages';
import ChatWindow from '../../molecules/ChatWindow';

const VoluntarieChat = () => {
  const [showVoluntarieDate, setShowVoluntarieDate] = useState(false);
  const user = useSelector((state) => state.user);
  const currentRoom = useSelector((state) => state.room.currentRoom);

  const params = {
    idRoom: currentRoom._idUsuarie,
    accessToken: user.token,
    userType: 'voluntarie',
  };
  const {
    isLoading: isLoadingMessages,
    hasError,
    data: voluntarieChats,
  } = useRequestMessages(params);

  useEffect(() => {
    fetchToggles().then((res) => {
      const showVoluntarieChatDate = res?.REACT_APP_SHOW_VOLUNTARIE_CHAT_DATE;
      if (!showVoluntarieChatDate) return;

      setShowVoluntarieDate(showVoluntarieChatDate.asBoolean());
    });
  }, []);

  return (
    <ChatWindow
      messages={voluntarieChats}
      hasError={hasError}
      showDate={showVoluntarieDate}
      isLoadingMessages={isLoadingMessages}
    />
  );
};

export default VoluntarieChat;
