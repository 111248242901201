import { useState } from 'react';
import { Button, Box, useToast } from '@chakra-ui/react';
import showAlert from '../../../utils/showAlert';
import Tag from './Tag';
import { tags } from './tags';

const UsuarieTags = ({ onSave, room }) => {
  const toast = useToast();
  const [clickedTags, setClickedTags] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const handleSaveTags = async () => {
    setIsSaving(true);
    const status = await onSave(clickedTags);
    if (status) {
      showAlert({
        toastFunc: toast,
        status: 'success',
        description: 'Los tags se han guardado con éxito.',
      });
    } else {
      showAlert({
        toastFunc: toast,
        status: 'error',
        description: 'Ocurrió un error al guardar. Vuelve a intentarlo.',
      });
    }
    setIsSaving(false);
  };

  const handleTagClick = (name, tagged) => {
    setClickedTags((prevState) => ({
      ...prevState,
      [name]: tagged,
    }));
  };

  return (
    <>
      <Box
        display="flex"
        height="100%"
        flexDirection="column"
        data-testid="tags-panel-container"
      >
        <Box
          display="flex"
          flex-wrap="wrap"
          flexFlow="wrap"
          h="85%"
          overflow="auto"
          mx={-2}
        >
          {tags.map((tag) => {
            return (
              <Tag
                key={tag.id}
                label={tag.label}
                name={tag.name}
                room={room}
                checked={room[tag.name] ? true : false}
                onClick={handleTagClick}
              />
            );
          })}
        </Box>
        <Box
          h="15%"
          display="flex"
          alignItems="end"
          flex-wrap="wrap"
        >
          <Button
            isLoading={isSaving}
            h="80%"
            maxHeight="3rem"
            variant="primary"
            bgColor="mora.500"
            textColor="#FFFFFF"
            w="100%"
            onClick={handleSaveTags}
          >
            Guardar
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UsuarieTags;
