import { WELCOME_MESSAGE } from '../../../utils/strings';
import ChatDate from '../../molecules/ChatDate';
import { CloseSessionMessage } from '../../molecules/CloseSessionMessage/CloseSessionMessage';
import Loader from '../../molecules/Loader';
import ChatBubble from '../../molecules/ChatBubble';
import ErrorTemplate from '../../templates/ErrorTemplate';
import { useSelector } from 'react-redux';

const Message = ({ chat, currentRoom, user }) => {
  const { closeSession, text, senderId, ts } = chat;
  const isUsuarie = user.userType === 'usuarie';

  if (closeSession) {
    return (
      <CloseSessionMessage
        isUsuarie={isUsuarie}
        message={text}
      />
    );
  }

  const isSender = isUsuarie
    ? user.uuid === senderId
    : currentRoom._idUsuarie !== senderId;

  return (
    <ChatBubble
      content={text}
      isSentMessage={isSender}
      timestamp={!isUsuarie && ts}
    />
  );
};

const ChatWindow = ({ messages, hasError, showDate, isLoadingMessages }) => {
  const currentRoom = useSelector((state) => state.room.currentRoom);
  const isSendingMessage = useSelector(
    (state) => state.loading.isSendingMessage
  );
  const user = useSelector((state) => state.user);
  const isReady = !isLoadingMessages && user.uuid !== '';
  return (
    <>
      {hasError && (
        <ErrorTemplate text="Lo sentimos, ha ocurrido un error al cargar el chat" />
      )}
      {isReady && <ChatBubble content={WELCOME_MESSAGE} />}
      {!isReady && (
        <Loader
          testId="loaderLoadChat"
          text="Cargando chat"
        />
      )}
      {messages.map((chat, index, chats) => (
        <div key={`chat-${index}`}>
          {showDate && (
            <ChatDate
              timestamp={chat.ts}
              index={index}
              chats={chats}
            />
          )}
          <Message
            user={user}
            chat={chat}
            index={index}
            currentRoom={currentRoom}
          />
        </div>
      ))}
      {isSendingMessage && (
        <Loader
          testId="loaderSendMessage"
          text="Enviando mensaje"
          type="horizontal"
        />
      )}
    </>
  );
};

export default ChatWindow;
