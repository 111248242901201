import { auth } from '../services/FirebaseAuthService';
import { signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';

export const sendPhoneCode = (prefix, number, recaptchaVerifier) => {
  const formatPhoneNumber = `${prefix}${number}`;
  const signedResponse = signInWithPhoneNumber(
    auth,
    formatPhoneNumber,
    recaptchaVerifier
  );
  return signedResponse;
};

export const RenderRecaptcha = () => {
  const recaptchaVerifier = new RecaptchaVerifier(
    'recaptcha-container',
    {},
    auth
  );
  return recaptchaVerifier;
};
