import { Box, Container, Flex, Text } from '@chakra-ui/react';
import { useState, useEffect } from 'react';

import { fetchToggles } from '../../../../services/FirebaseRemoteConfig';
import UserInfo from '../../../molecules/UserInfo/UserInfo';
import PollButton from '../../../atoms/PollButton';
import UserDetailTabs from '../UserDetailTabs';

const UserData = () => {
  const [pollButtonEnable, setPollButtonEnable] = useState(false);

  useEffect(() => {
    fetchToggles().then((res) => {
      const showPollButton = res?.REACT_APP_POLL_BUTTON_ENABLE;
      if (!showPollButton) return;
      setPollButtonEnable(showPollButton.asBoolean());
    });
  }, []);

  return (
    <Box
      w="26%"
      h="100%"
    >
      <Flex
        textAlign="start"
        ml="6"
        alignItems="center"
      >
        <Text
          my="16px"
          textStyle="h3"
        >
          Ficha Usuarie
        </Text>
      </Flex>
      <Container h="90%">
        <UserInfo />
        <Box
          h="2.5%"
          display="flex"
          alignItems="center"
        ></Box>
        <UserDetailTabs />
        <Box
          h="2.5%"
          display="flex"
          alignItems="center"
        ></Box>
        <Box
          h="10%"
          alignItems="center"
          display="flex"
        >
          {pollButtonEnable && <PollButton />}
        </Box>
      </Container>
    </Box>
  );
};

export default UserData;
