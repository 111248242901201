import {
  Button,
  HStack,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { logoutAuth } from '../../../services/FirebaseAuthService';
import { useDispatch, useSelector } from 'react-redux';
import { pinSentActions } from '../../../store/Slices/pinSent';

const LogOutButton = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const logout = async () => {
    dispatch(pinSentActions.setPinNotSent());
    localStorage.removeItem('voluntarie');
    await logoutAuth();
    navigate(
      user.userType === 'voluntarie' ? '/voluntaries' : '/usuaries/auth'
    );
  };

  return (
    <>
      <Popover placement="bottom-end">
        <PopoverTrigger>
          <Button
            role="listbox"
            _hover={{ bg: 'white' }}
            bg="white"
            data-testid="dropdownLogOut"
          >
            <Text textStyle="body2">{user.displayName}</Text>
            <Image
              alt=""
              ml="2"
              src="/icons/down.svg"
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent w="150px">
          <PopoverBody textAlign="center">
            <HStack
              as={'button'}
              role="button"
              aria-label="Cerrar Sesión"
              onClick={logout}
              cursor="pointer"
              data-testid="logOutButton"
            >
              <Image
                alt=""
                fill="mora.500"
                src="/icons/signout.svg"
              />
              <Text data-testid="logout-text">Cerrar Sesión</Text>
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};
export default LogOutButton;
