import { pinButtonColor } from '../../utils/pinButtonColor';
import ContainerCenterContent from './ContainerCenterContent';
import { Button } from '@chakra-ui/react';
const PinButtonLogin = ({ onClick, validPin }) => {
  return (
    <ContainerCenterContent
      alignContent="center"
      textAlign="center"
      mt="1.65625rem"
    >
      <Button
        data-testid="usuarieEnterButton"
        bgColor={pinButtonColor(validPin)}
        w="20rem"
        h="2.875rem"
        onClick={onClick}
        isDisabled={!validPin}
        variant="primary"
      >
        Ingresar a la hora segura
      </Button>
    </ContainerCenterContent>
  );
};

export default PinButtonLogin;
