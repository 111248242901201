const WORKING_HOURS = {
  LUNCH_START: 13,
  LUNCH_END: 14,
  DINNER_START_FIRST_LAPSE: 18,
  DINNER_START_SECOND_LAPSE: 17,
  DINNER_END: 24,
  WEEKEND_DINNER_START: 19,
};

const isWorkingHours = (day, hour) => {
  const lunchCondition =
    hour >= WORKING_HOURS.LUNCH_START && hour < WORKING_HOURS.LUNCH_END;

  const dinnerFirstCondition =
    hour >= WORKING_HOURS.DINNER_START_FIRST_LAPSE &&
    hour <= WORKING_HOURS.DINNER_END;

  const dinnerSecondCondition =
    hour >= WORKING_HOURS.DINNER_START_SECOND_LAPSE &&
    hour <= WORKING_HOURS.DINNER_END;

  const weekendDinnerCondition =
    hour >= WORKING_HOURS.WEEKEND_DINNER_START &&
    hour <= WORKING_HOURS.DINNER_END;

  return (
    ((day === 1 || day === 2) && (lunchCondition || dinnerFirstCondition)) ||
    (day >= 3 && day <= 5 && (lunchCondition || dinnerSecondCondition)) ||
    ((day === 0 || day === 6) && (lunchCondition || weekendDinnerCondition))
  );
};

export default isWorkingHours;
