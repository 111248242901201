import { Button, Container, Flex, Image, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const VoluntarieWelcomePage = () => {
  const navigate = useNavigate();
  const redirectToAuth = () => navigate('/voluntaries/auth');

  return (
    <Flex
      w="100vw"
      h="100vh"
      direction="column"
      alignItems="center"
      justifyItems="center"
      mt="4rem"
    >
      <Container
        centerContent
        data-testid="welcome-voluntaries"
      >
        <Image
          src={'/images/logo-todo-mejora-icon.svg'}
          alt={'Logo Hora Segura'}
          w="80px"
          h="80px"
          mb="10px"
          mt={6}
        />

        <Text
          textStyle="xl_bold"
          textAlign="center"
          color="mora.600"
          w="368px"
        >
          Bienvenide Voluntarie
        </Text>
        <Text
          textStyle="regular"
          color="carbon.500"
          w="400px"
          mt="8px"
          mb="32px"
          textAlign="left"
        >
          Con nuestro Programa Hora Segura, línea de ayuda por chat atendemos a
          miles de niñes, niñas, niños, adolescentes y a su entorno protector.
        </Text>

        <Image
          src={'/images/family-support.svg'}
          alt={'Family support'}
          width="400px"
          height="281px"
          mb="32px"
          ml="-7"
        />

        <Button
          variant="primary"
          w="441px"
          onClick={redirectToAuth}
          data-testid="voluntarieEnterButton"
        >
          Entrar
        </Button>
      </Container>
    </Flex>
  );
};

export default VoluntarieWelcomePage;
