import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/react';
import { roomActions } from '../../store/Slices/room';
import { useDispatch, useSelector } from 'react-redux';
import { closeChat, getRoomByUsuarieId } from '../../services/Rooms';
import { sendVoluntarieMessage } from '../../services/Messages';
import { logErrorEvent } from '../../helpers/logAnalytics';
import ERROR_CATALOG from '../../helpers/errorCatalog';

const ModalCloseChat = ({ isOpen, onClose, room }) => {
  const dispatch = useDispatch();

  const currentRoom = useSelector((state) => state.room.currentRoom);
  const voluntarie = useSelector((state) => state.user);

  const handleClickCloseChat = async () => {
    const message = {
      text: 'Se ha cerrado la conversación',
      senderId: voluntarie.uuid,
      roomId: currentRoom._idUsuarie,
      sessionCounter: currentRoom.sessionCounter,
      accessToken: voluntarie.token,
      usuarieUuid: currentRoom.uuid,
      closeSession: true,
    };

    try {
      await sendVoluntarieMessage(message);
      await closeChat(room.uuid);
      const newCurrentRoom = await getRoomByUsuarieId(room._idUsuarie);
      dispatch(roomActions.setRoom(newCurrentRoom));
    } catch (error) {
      logErrorEvent({
        message: error.message,
        component_name: ERROR_CATALOG.VOLUNTARIES.CLOSING_CHAT,
      });
      console.error('Something went wrong closing chat', error);
    } finally {
      onClose();
    }
  };
  return (
    <>
      <Modal
        onClose={onClose}
        size={'md'}
        isOpen={isOpen}
      >
        <ModalOverlay />
        <ModalContent
          display="flex"
          justifyContent="center"
          pt="1%"
        >
          <ModalHeader
            display="flex"
            justifyContent="center"
            textAlign="center"
            pb="1px"
          >
            Cerrar Chat
          </ModalHeader>

          <ModalBody
            display="flex"
            justifyContent="center"
            textAlign="justify"
            pt="5%"
          >
            ¿Estás seguro que deseas cerrar este chat?
          </ModalBody>
          <ModalFooter
            display="flex"
            justifyContent="space-evenly"
          >
            <Button
              mx="12px"
              px="36px"
              py="20px"
              data-testid="cancelFinalizeChatButton"
              variant="primary-outline"
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              mx="12px"
              px="42px"
              py="22px"
              data-testid="confirmFinalizeChatButton"
              variant="primary"
              onClick={handleClickCloseChat}
            >
              Aceptar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalCloseChat;
