export const tags = [
  { id: 1, name: 'lgbtiq', label: 'LGBTIQ+' },
  { id: 2, name: 'osieg', label: 'OSIEG' },
  { id: 3, name: 'trans', label: 'Trans' },
  { id: 4, name: 'sintomatologia_ansiosa', label: 'Sintomatología ansiosa' },
  { id: 5, name: 'no_binario', label: 'No binario' },
  { id: 6, name: 'salida_del_closet', label: 'Salida del clóset' },
  { id: 7, name: 'nna', label: 'NNA' },
  { id: 8, name: 'acoso_o_maltrato', label: 'Acoso o maltrato' },
  { id: 9, name: 'cyberbullyng', label: 'Cyberbullying' },
  {
    id: 10,
    name: 'educacion_o_salud_sexual_reproductiva',
    label: 'Educación o salud sexual / reproductiva',
  },
  { id: 11, name: 'comportamiento_suicida', label: 'Comportamiento suicida' },
  { id: 12, name: 'vulneracion_de_derechos', label: 'Vulneración de derechos' },
  {
    id: 13,
    name: 'sintomatologia_depresiva',
    label: 'Sintomatología depresiva',
  },
  { id: 14, name: 'no_caracterizable', label: 'No caracterizable' },
  { id: 15, name: 'violencia_en_la_pareja', label: 'Violencia en la pareja' },
  {
    id: 16,
    name: 'solicita_otras_informaciones',
    label: 'Solicita otras informaciones',
  },
  { id: 17, name: 'consumo_de_sustancias', label: 'Consumo de sustancias' },

  { id: 18, name: 'conversacion_exitosa', label: 'Conversación exitosa' },
  { id: 19, name: 'caracterizacion_ok', label: 'Caracterización OK' },
  { id: 20, name: 'derivacion', label: 'Derivación' },
  { id: 21, name: 'persona_migrante', label: 'Persona Migrante' },
  { id: 22, name: 'rechazo_familiar', label: 'Rechazo Familiar' },
  { id: 23, name: 'entorno_protector', label: 'Entorno Protector' },
  { id: 24, name: 'trastorno_alimentario', label: 'Tr. Alimentario' },
];
