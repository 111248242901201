import { createSlice } from '@reduxjs/toolkit';

const initialLoadingState = { isSendingMessage: false };
const loadingSlice = createSlice({
  name: 'send message loading',
  initialState: initialLoadingState,
  reducers: {
    setSendingMessage(state, action) {
      state.isSendingMessage = action.payload;
    },
  },
});

export const loadingActions = loadingSlice.actions;
export const loadingReducers = loadingSlice.reducer;
export default loadingSlice;
