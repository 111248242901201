import { HStack, PinInput, PinInputField } from '@chakra-ui/react';
import ContainerCenterContent from './ContainerCenterContent';
import { useSelector, useDispatch } from 'react-redux';
import { validatePinSix } from '../../utils/pinValidator';
import { pinSentActions } from '../../store/Slices/pinSent';

const PinInputUsuarie = ({ onChange }) => {
  const dispatch = useDispatch();
  const valuePin = useSelector((state) => state.pinSent.usuariePin);

  const validarPin = (event) => {
    dispatch(pinSentActions.setUsuariePin(event));
    onChange({ isValid: validatePinSix(event), value: event });
  };
  return (
    <ContainerCenterContent mt="3.3125rem">
      <HStack>
        <PinInput
          otp
          type="number"
          borderColor="mora.500"
          focusBorderColor="mora.500"
          onChange={validarPin}
          value={valuePin}
        >
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </PinInput>
      </HStack>
    </ContainerCenterContent>
  );
};

export default PinInputUsuarie;
