import { createContext } from 'react';
import { Navigate } from 'react-router-dom';
export const userAuthContext = createContext();

export const VoluntariesAuthProvider = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('voluntarie'));

  if (!user) return <Navigate to="/" />;
  return <>{children}</>;
};
