const showAlert = ({ status, description, toastFunc }) => {
  toastFunc({
    position: 'bottom-right',
    description: description,
    status: status,
    variant: 'subtle',
    duration: 2000,
    isClosable: true,
    containerStyle: {
      paddingRight: '4.5rem',
      marginBottom: '16',
      marginTop: '-12',
    },
  });
};

export default showAlert;
