import { Flex, useBoolean, Box, Text } from '@chakra-ui/react';
import ChatInputBar from '../../../components/molecules/ChatInputBar';
import HeaderComponent from '../../../components/molecules/HeaderComponent/HeaderComponent';
import UsuarieChat from '../../../components/templates/UsuarieChat';

const UsuarieChatPage = () => {
  const [options] = useBoolean();

  return (
    <Flex
      w="100%"
      flexDirection="column"
      h="100vh"
    >
      <Box
        h="10%"
        w="100%"
      >
        <HeaderComponent
          isVoluntarie={false}
          showDownloadButtons={false}
        />
      </Box>

      {options && (
        <Box
          m="0"
          h="50px"
          w="100%"
          bg="white"
          p="4"
        >
          <Text
            fontSize="14px"
            textDecoration="underline"
          >
            Revisar términos y condiciones
          </Text>
        </Box>
      )}
      <Box
        w="100%"
        height="80%"
        backgroundColor="#e5e5e5"
        p="6"
        overflow="auto"
      >
        <UsuarieChat />
      </Box>
      <Box
        h="10%"
        w="100%"
      >
        <ChatInputBar />
      </Box>
    </Flex>
  );
};

export default UsuarieChatPage;
