import { Button } from '@chakra-ui/react';
const PollButton = ({ ...buttonProps }) => {
  const openSurvey = () =>
    window.open(
      'https://encuesta-caracterizacion.herokuapp.com/',
      '_blank',
      'noopener'
    );

  return (
    <Button
      {...buttonProps}
      h="90%"
      maxHeight="3rem"
      whiteSpace="normal"
      lineHeight="initial"
      w="100%"
      variant="primary"
      onClick={openSurvey}
      data-testid="poll-button"
    >
      Llenar encuesta de caracterización
    </Button>
  );
};
export default PollButton;
