// QEYBy4N7xXCGCHmvpKbp

// "nifmuFe5EfT2spPRgNiKt7RO1Cn2"
import { collection, query, getDocs } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { tags } from '../components/molecules/UsuarieTags/tags';
import { decryptText } from '../utils/messageEncryption';
import moment from 'moment';
import { utils, writeFile } from 'xlsx-js-style';

const color = {
  white: 'FFFFFF',
  grey: 'F3F3F3',
  dark_grey: '666666',
  black: '000000',
};

export const downloadData = async (start_date, end_date) => {
  const local_start_date =
    Date.parse(start_date) +
    new Date(start_date).getTimezoneOffset() * 60 * 1000;

  const local_end_date =
    Date.parse(end_date) +
    new Date(end_date).getTimezoneOffset() * 60 * 1000 +
    86399000;

  const chatsRef = query(collection(firestore, 'chatV02'));
  const chatCollection = await getDocs(chatsRef);
  const chatsArray = [];

  chatCollection.forEach((doc) => {
    const data = doc.data();
    let sessions = data.messages ? groupBy(data.messages, 'session') : {};

    const conversations = Object.values(sessions);

    conversations.forEach((conversation) => {
      const userMessages = conversation.filter(
        (message) =>
          message.senderId == doc.id &&
          message.ts >= local_start_date &&
          message.ts <= local_end_date
      );
      const volunteerMessages = conversation.filter(
        (message) =>
          message.senderId != doc.id &&
          message.ts >= local_start_date &&
          message.ts <= local_end_date
      );
      const totalUserMessages = userMessages?.length;
      const totalVolunteerMessages = volunteerMessages?.length;
      const date = userMessages
        ? moment(userMessages[0]?.ts).format('DD/MM/YYYY')
        : '';
      const firstMessageUser =
        userMessages && totalUserMessages > 0
          ? moment(userMessages[0]?.ts).format('HH:mm:ss')
          : '';
      const firstMessageVolunteer =
        volunteerMessages && totalVolunteerMessages > 0
          ? moment(volunteerMessages[0]?.ts).format('HH:mm:ss')
          : '';
      const lastMessageUser =
        userMessages && totalUserMessages > 0
          ? moment(userMessages[userMessages.length - 1]?.ts).format('HH:mm:ss')
          : '';
      const lastMessageVolunteer =
        volunteerMessages && totalVolunteerMessages
          ? moment(volunteerMessages[volunteerMessages.length - 1]?.ts).format(
              'HH:mm:ss'
            )
          : '';
      const dateFinalConversation = conversation[conversation.length - 1]?.ts;
      const lastDate = new Date(dateFinalConversation);

      const dateInitialConversation = conversation[0]?.ts;
      const firstDate = new Date(dateInitialConversation);

      const timeDifference = lastDate - firstDate;

      const seconds = timeDifference / 1000;
      const minutes = seconds / 60;
      const hours = minutes / 60;

      const conversationDuration = hours;

      const row = {
        Identificador: doc.id,
        Fecha: date,
        'Hora primer mensaje usuarie': firstMessageUser,
        'Hora primer mensaje voluntarie': firstMessageVolunteer,
        'Cantidad de mensajes usuarie': totalUserMessages,
        'Cantidad de mensajes voluntarie': totalVolunteerMessages,
        'Hora del último mensaje del usuarie': lastMessageUser,
        'Hora del último mensaje del voluntarie': lastMessageVolunteer,
        'Duración conversación en horas': conversationDuration,
      };
      if (totalUserMessages + totalVolunteerMessages > 0) chatsArray.push(row);
    });
  });

  const worksheetChats = utils.json_to_sheet(chatsArray);

  const q = query(collection(firestore, 'usuaries'));
  const querySnapshot = await getDocs(q);
  const array = [];
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    const row = {
      Identificador: data._idUsuarie,
      Comuna: data.commune,
      Edad: data.age,
      'Identidad de Genero': data.genderIdentity,
      'Sexo biologico': data.bioSex,
      'Orientacion sexo afectiva': data.sexualOrientation,
      Atributos: decryptText(data.attributes).slice(0, 32766),
    };
    for (const tag of tags) {
      row[`Tag ${tag.label}`] = data[tag.name] ? 'Si' : 'No';
    }
    if (
      chatsArray.filter((chat) => chat.Identificador === data._idUsuarie)
        .length > 0
    ) {
      array.push(row);
    }
  });

  if (array.length > 0) {
    const worksheetUsuaries = utils.json_to_sheet(array);
    const workbook = utils.book_new();
    applyStyle(worksheetUsuaries);
    applyStyle(worksheetChats);
    utils.book_append_sheet(workbook, worksheetUsuaries, 'Usuaries');
    utils.book_append_sheet(workbook, worksheetChats, 'Conversaciones');
    writeFile(workbook, 'Hora Segura.xlsx');
  } else {
    throw Error('No se encontraron conversaciones');
  }
};

const applyStyle = (sheet) => {
  const borderStyle = { style: 'thin', color: { rgb: color.black } };
  const range = utils.decode_range(sheet['!ref']);
  sheet['!cols'] = [{ wpx: 170 }];
  for (let colIndex = range.s.c; colIndex <= range.e.c; ++colIndex) {
    const headerCell = utils.encode_cell({ r: 0, c: colIndex });
    sheet[headerCell].s = {
      alignment: { horizontal: 'center' },
      font: { bold: true, color: { rgb: color.white }, sz: 12 },
      fill: { fgColor: { rgb: color.dark_grey } },
      border: {
        top: borderStyle,
        bottom: borderStyle,
        left: borderStyle,
        right: borderStyle,
      },
    };

    for (let rowIndex = range.s.r + 1; rowIndex <= range.e.r; ++rowIndex) {
      const cell = utils.encode_cell({ r: rowIndex, c: colIndex });
      sheet[cell].s = {
        font: { sz: 10 },
        alignment: { horizontal: 'center', wrapText: true },
        fill: {
          fgColor: { rgb: rowIndex % 2 == 0 ? color.grey : color.white },
        },
        border: {
          top: borderStyle,
          bottom: borderStyle,
          left: borderStyle,
          right: borderStyle,
        },
      };
    }
  }
};
const groupBy = (arr, property) => {
  return arr.reduce(function (mainObject, arrayIndex) {
    if (!mainObject[arrayIndex[property]]) {
      mainObject[arrayIndex[property]] = [];
    }
    mainObject[arrayIndex[property]].push(arrayIndex);
    return mainObject;
  }, {});
};
