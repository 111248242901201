import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebaseConfig';

const logErrorEvent = (errorMessage) => {
  logEvent(analytics, 'error_event', errorMessage);
};

const logEventAnalytics = (message) => {
  logEvent(analytics, message);
};

export { logErrorEvent, logEventAnalytics };
