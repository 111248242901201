import { useState, useEffect } from 'react';
import { useCheckbox, Tag as ChakraTag, Text } from '@chakra-ui/react';

const Tag = ({ label, name, checked, onClick, room }) => {
  const [tagged, setTagged] = useState(checked);

  const { state, getInputProps, getCheckboxProps, getLabelProps, htmlProps } =
    useCheckbox({
      isChecked: tagged,
    });

  const onPress = () => {
    setTagged(!tagged);
    onClick(name, !tagged);
  };

  useEffect(() => {
    setTagged(checked);
  }, [room]);

  return (
    <ChakraTag
      role="checkbox"
      aria-label={label}
      aria-checked={tagged}
      data-testid="tags-chip"
      height={6}
      fontSize={13}
      bg={state.isChecked ? 'mora.500' : 'white'}
      style={{ margin: '2px' }}
      border="0.5px solid"
      borderColor="carbon.500"
      rounded="md"
      cursor="pointer"
      whiteSpace="nowrap"
      tabIndex={0}
      onClick={onPress}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          onPress();
        }
      }}
      {...htmlProps}
    >
      <input
        name={name}
        hidden
        {...getInputProps()}
        {...getCheckboxProps()}
      />
      <Text
        color={tagged ? 'white' : 'carbon.400'}
        {...getLabelProps()}
      >
        {label}
      </Text>
    </ChakraTag>
  );
};

export default Tag;
