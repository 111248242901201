import { remoteConfig } from '../firebaseConfig';
import { fetchAndActivate, getAll } from 'firebase/remote-config';

export const fetchToggles = async () => {
  return fetchAndActivate(remoteConfig)
    .then(() => {
      return getAll(remoteConfig);
    })
    .catch(console.error);
};
