import { combineReducers } from 'redux';
import { roomReducers } from './Slices/room';
import { messagesReducers } from './Slices/messages';
import { phoneNumberReducers } from './Slices/phoneNumber';
import { userReducers } from './Slices/users';
import { pinSentReducers } from './Slices/pinSent';
import { loadingReducers } from './Slices/loading';

const rootReducer = combineReducers({
  room: roomReducers,
  messages: messagesReducers,
  phoneNumber: phoneNumberReducers,
  user: userReducers,
  pinSent: pinSentReducers,
  loading: loadingReducers,
});

export default rootReducer;
