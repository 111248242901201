import { Box, Text } from '@chakra-ui/react';

export const CloseSessionMessage = ({ message, isUsuarie = false }) => {
  const positionLineSeparator = !isUsuarie ? { right: '25%' } : { left: '0%' };
  return (
    <Box
      position="relative"
      paddingTop="4"
    >
      <Box
        h="0.5"
        w="23px"
        {...positionLineSeparator}
        bg="gray.400"
        position="absolute"
        data-testid="lineSeparator"
      />
      <Text
        data-testid="closedConversationMessage"
        color="gray.500"
        textAlign={!isUsuarie ? 'end' : 'start'}
        marginTop="10px"
        fontSize="14"
      >
        {message}
      </Text>
    </Box>
  );
};
