import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getVoluntarieById } from '../../../services/Volunteer';
import { DownloadIcon } from '@chakra-ui/icons';
import ButtonWithLeftIcon from '../../atoms/ButtonWithLeftIcon';
import { useDisclosure } from '@chakra-ui/react';
import ModalDownloadData from '../ModalDownloadData';
import { logErrorEvent } from '../../../helpers/logAnalytics';
import ERROR_CATALOG from '../../../helpers/errorCatalog';

const ButtonDownloadReport = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const voluntarie = useSelector((state) => state.user);
  const [voluntarieProfile, setVoluntarieProfile] = useState('');

  const getVoluntarieProfile = async () => {
    if (voluntarie.uuid != '') {
      try {
        const voluntarieData = await getVoluntarieById(voluntarie.uuid);
        if (voluntarieData) setVoluntarieProfile(voluntarieData.profile);
      } catch (error) {
        logErrorEvent({
          message: error.message,
          component_name: ERROR_CATALOG.VOLUNTARIES.DOWNLOAD_DATA,
        });
      }
    }
  };

  useEffect(() => {
    getVoluntarieProfile();
  }, [voluntarie]);

  return (
    voluntarieProfile === 'coordinator' && (
      <>
        <ButtonWithLeftIcon
          data-testid="download-data-button"
          text={'Descargar reporte'}
          iconSource={<DownloadIcon />}
          onClick={onOpen}
        />
        <ModalDownloadData
          isOpen={isOpen}
          onClose={onClose}
        />
      </>
    )
  );
};
export default ButtonDownloadReport;
