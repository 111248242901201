import { ChakraProvider } from '@chakra-ui/react';
import theme from './themes/todomejora';
import AppRouter from './navigations/Routes';
import ErrorBoundary from './pages/error/ErrorBoundary';

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <ErrorBoundary>
        <AppRouter />
      </ErrorBoundary>
    </ChakraProvider>
  );
};

export default App;
