import firebase from 'firebase/compat/app';
import { getFirestore } from 'firebase/firestore';
import 'firebase/compat/auth';
import { getPerformance } from 'firebase/performance';
import { getRemoteConfig } from 'firebase/remote-config';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
};

const app = firebase.initializeApp(firebaseConfig);
const firestore = getFirestore();

// eslint-disable-next-line no-unused-vars
const perf = getPerformance(app);

const analytics = getAnalytics(app);

const remoteConfig = getRemoteConfig(app);
const timeInterval = process.env.REACT_APP_REMOTE_CONFIG_INTERVAL || 25000;
remoteConfig.settings.minimumFetchIntervalMillis = timeInterval;

const getJsonConfig = async () => {
  const rcDefaultsFile = await fetch('../remote_config.json');
  const rcDefaultsJson = await rcDefaultsFile.json();
  remoteConfig.defaultConfig = rcDefaultsJson;
};
getJsonConfig();

export { app, firestore, remoteConfig, analytics };
