import {
  Button,
  Container,
  Flex,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import TMLinkButton from '../../../components/atoms/TMLinkButton';
import ModalTermsAndConditions from '../../../components/molecules/ModalTermsAndConditions';
import ModalOutOfWorkingHours from '../../../components/molecules/ModalOutOfWorkingHours';
import { ENABLE_MODAL_OF_WORKING } from '../../../utils/featureToggle';

const UsuarieWelcomePage = () => {
  const navigate = useNavigate();
  const handleClick = () => navigate('/usuaries/auth');
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      w="100%"
      direction="column"
      mt="7"
      mb="3"
      alignSelf="center"
    >
      <Container centerContent>
        <Image
          src={'/images/logo-todo-mejora-icon.svg'}
          alt={'logo corazon hora segura'}
          boxSize={'62px'}
        />
      </Container>
      <Container
        maxW="md"
        mt="1.75rem"
        centerContent
      >
        <Text
          textStyle="subtitle"
          color="mora.500"
          textAlign="center"
        >
          Te damos la bienvenida a
        </Text>
        <Text
          textStyle="h1"
          color="mora.500"
        >
          La Hora Segura
        </Text>
      </Container>
      <Container centerContent>
        <Image
          src={'/images/family-support.svg'}
          alt={'personas cuidando un corazón'}
          w={'306px'}
          h={'auto'}
          mt={'24px'}
          mb={'24px'}
        />
      </Container>
      <Container
        maxW="md"
        centerContent
      >
        <Text
          textStyle="body1"
          textColor="carbon.500"
          px="24px"
        >
          En la Hora Segura entregamos contención y orientación confidencial a
          niñes, adolescentes y jóvenes que tengan problematicas asociadas al
          género.
        </Text>
      </Container>
      <Container
        alignContent="center"
        textAlign="center"
        mt="24px"
      >
        <Button
          textStyle="button"
          data-testid="usuarieNeedHelpButton"
          variant="primary"
          onClick={handleClick}
        >
          Necesito contención y orientación
        </Button>
      </Container>
      <Container
        alignContent="center"
        textAlign="center"
        mt="0.75rem"
      >
        <Button
          data-testid="someoneElseButton"
          variant="primary-outline"
          onClick={handleClick}
        >
          Quiero preguntar por alguien más
        </Button>
      </Container>
      <Container
        maxW="md"
        mt="1.75rem"
        centerContent
        data-testid="scheduleModal"
      >
        <TMLinkButton
          data-testid="terminosCondicionesButton"
          onClick={onOpen}
        >
          Términos y condiciones
        </TMLinkButton>
        <ModalTermsAndConditions
          isOpen={isOpen}
          onClose={onClose}
        />

        {ENABLE_MODAL_OF_WORKING && <ModalOutOfWorkingHours />}
      </Container>
    </Flex>
  );
};

export default UsuarieWelcomePage;
