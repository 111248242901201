import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import UsuarieWelcomePage from '../pages/usuaries/UsuarieWelcomePage';
import VoluntarieWelcomePage from '../pages/voluntaries/VoluntarieWelcomePage';
import UsuarieRegisterPage from '../pages/usuaries/UsuarieRegisterPage';
import VoluntarieOverviewPage from '../pages/voluntaries/VoluntarieOverviewPage';
import VoluntarieLoginPage from '../pages/voluntaries/VoluntarieLoginPage';
import UsuarieLoginPage from '../pages/usuaries/UsuarieLoginPage';
import UsuarieChatPage from '../pages/usuaries/UsuarieChatPage';
import { VoluntariesAuthProvider } from '../context/VoluntariesAuthContext';
import TermsAndConditionsPage from '../pages/usuaries/TermsAndConditionsPage';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={<UsuarieWelcomePage />}
        />
        <Route
          exact
          path="/termsConditions"
          element={<TermsAndConditionsPage />}
        />
        <Route
          exact
          path="/usuaries/welcome"
          element={<UsuarieWelcomePage />}
        />
        <Route
          exact
          path="/usuaries/auth"
          element={<UsuarieLoginPage />}
        />
        <Route
          exact
          path="/usuaries/form"
          element={<UsuarieRegisterPage />}
        />
        <Route
          exact
          path="/usuaries/chat"
          element={<UsuarieChatPage />}
        />
        <Route
          exact
          path="/voluntaries"
          element={<VoluntarieWelcomePage />}
        />
        <Route
          path="/voluntaries/auth"
          element={<VoluntarieLoginPage />}
        />
        <Route
          exact
          path="/voluntaries/chat"
          element={
            <VoluntariesAuthProvider>
              <VoluntarieOverviewPage />
            </VoluntariesAuthProvider>
          }
        />
      </Routes>
    </Router>
  );
};

export { AppRouter as default };
