import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import TermsAndConditions from '../../pages/usuaries/TermsAndConditionsPage';

const ModalTermsAndConditions = ({ isOpen, onClose }) => {
  return (
    <>
      <Modal
        onClose={onClose}
        size={'full'}
        isOpen={isOpen}
      >
        <ModalOverlay />
        <ModalContent
          display="flex"
          justifyContent="center"
          px="2%"
          pt="5%"
        >
          <ModalHeader
            display="flex"
            justifyContent="center"
            textAlign="center"
            pb="1px"
          >
            TÉRMINOS Y CONDICIONES DE USO FUNDACIÓN TODO MEJORA CHILE
          </ModalHeader>
          <ModalHeader
            display="flex"
            justifyContent="center"
            pt="0"
          >
            05/09/2022
          </ModalHeader>
          <ModalCloseButton
            data-testid="termsModalCloseButton"
            position="fixed"
          />
          <ModalBody
            display="flex"
            justifyContent="center"
            textAlign="justify"
            pt="5%"
          >
            <TermsAndConditions />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalTermsAndConditions;
