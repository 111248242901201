import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Image,
  Input,
  InputGroup,
  Text,
  VisuallyHidden,
  FormErrorMessage,
  InputRightElement,
} from '@chakra-ui/react';
import FirebaseAuthService from '../../../services/FirebaseAuthService';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../store/Slices/users';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

const VoluntarieLoginPage = () => {
  const [isUserAndPasswordFilled, setIsUserAndPasswordFilled] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hasUserPassError, setHasUserPassError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const emailValidator = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEmailInput = (e) => {
    setHasUserPassError(false);

    emailValidator.test(e.target.value)
      ? setEmail(e.target.value)
      : setEmail('');
  };

  const validateInputValues = (e) => {
    setHasUserPassError(false);
    const password = e.target.value;

    if (password === '') setIsUserAndPasswordFilled(false);
    if (email && password) {
      setPassword(password);
      setIsUserAndPasswordFilled(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await FirebaseAuthService.loginVoluntarie(email, password).then(
        (cred) => {
          localStorage.setItem('voluntarie', JSON.stringify(cred.user.email));
          dispatch(
            userActions.setUser({
              userType: 'voluntarie',
              uuid: cred.user.auth.currentUser.uid,
              token: cred.user.auth.currentUser.accessToken,
              displayName: cred.user.auth.currentUser.email,
            })
          );
        }
      );
      setEmail('');
      setPassword('');
      navigate('/voluntaries/chat');
    } catch (error) {
      console.error(error);
      setHasUserPassError(true);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      mt="4rem"
    >
      <Image
        src={'/images/logo-todo-mejora-icon.svg'}
        w="100px"
        h="100px"
        mb={4}
      />
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        boxShadow="0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)"
        px="32px"
        py="40px"
      >
        <Text
          fontSize={'16px'}
          fontWeight={'700'}
          mb={'40px'}
        >
          Inicia sesión con tu cuenta de Todo Mejora.{' '}
        </Text>
        <FormControl
          as="form"
          isInvalid={hasUserPassError}
        >
          <FormLabel
            fontWeight="700"
            fontSize="16px"
          >
            Mail de Usuario
          </FormLabel>
          <Input
            mb={'32px'}
            borderColor="mora.400"
            focusBorderColor="aqua.500"
            errorBorderColor="#D6075B"
            type="email"
            fontWeight="400"
            lineHeight="22px"
            id="email"
            onChange={handleEmailInput}
            onBlur={handleEmailInput}
            autoComplete="email"
          />
          <FormLabel fontWeight="700">Contraseña</FormLabel>
          <InputGroup>
            <Input
              borderColor="mora.500"
              focusBorderColor="aqua.500"
              type={showPassword ? 'text' : 'password'}
              id="password"
              onChange={validateInputValues}
              onBlur={validateInputValues}
              autoComplete="current-password"
            />
            <InputRightElement>
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                bgColor={'transparent'}
                _hover={{ bgColor: 'transparent' }}
                icon={showPassword ? <ViewIcon /> : <ViewOffIcon />}
              />
            </InputRightElement>
          </InputGroup>
          {hasUserPassError && (
            <FormErrorMessage>Email y/o contraseña no válidos</FormErrorMessage>
          )}
        </FormControl>
        <Button
          align="center"
          mt={'40px'}
          w="100%"
          h="2.875rem"
          isDisabled={!isUserAndPasswordFilled}
          variant="primary"
          textColor="#FFFFFF"
          onClick={handleSubmit}
          data-testid="voluntarieAuthEnterButton"
        >
          Entrar
        </Button>
        <VisuallyHidden>
          <Button
            color="#5C186A"
            variant="link"
            mt={5}
            mb={5}
          >
            Olvidé mi Contraseña
          </Button>
        </VisuallyHidden>
      </Flex>
    </Box>
  );
};

export default VoluntarieLoginPage;
