import { useDispatch, useSelector } from 'react-redux';
import { roomActions } from '../../../store/Slices/room';
import { Box, Divider, Image, Text } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { sendLastTimeOpenedMessageByVoluntarie } from '../../../services/Messages';
import { fetchToggles } from '../../../services/FirebaseRemoteConfig';
import SelfInjuryTag from '../../atoms/SelfInjuryTag/SelfInjuryTag';

const ChatRoom = ({ room }) => {
  const dispatch = useDispatch();
  const currentRoom = useSelector((state) => state.room.currentRoom);
  const usuarieId = room.uuid;
  const roomInformation = room;
  const [backgroundColor, setBackgroundColor] = useState('carbon.0');
  const [showDotNewMessage, setShowDotNewMessage] = useState(false);

  const setBackgroundColorByRoom = (roomInformation, currentRoom) => {
    if (roomInformation.uuid === currentRoom.uuid) {
      return 'carbon.200';
    }
    if (roomInformation.lastTimeOpenedByVoluntarie == null) {
      return 'gold.100';
    }
    return 'carbon.0';
  };

  useEffect(() => {
    const backgroundColor = setBackgroundColorByRoom(
      roomInformation,
      currentRoom
    );
    setBackgroundColor(backgroundColor);
  }, [
    roomInformation.uuid,
    currentRoom.uuid,
    roomInformation.lastTimeOpenedByVoluntarie,
  ]);

  useEffect(() => {
    fetchToggles().then((res) => {
      const showGreenDot = res?.REACT_APP_SHOW_GREEN_DOT_NEW_MESSAGE;
      if (!showGreenDot) return;
      setShowDotNewMessage(showGreenDot.asBoolean());
    });
  }, []);

  const handlerOnClick = () => {
    dispatch(roomActions.setRoom(room));
    sendLastTimeOpenedMessageByVoluntarie(usuarieId);
  };

  return (
    <>
      <Box
        as="button"
        display="flex"
        flexDirection="column"
        borderRadius="4px"
        w="95%"
        maxHeight="80px"
        backgroundColor={backgroundColor}
        onClick={handlerOnClick}
      >
        <Box
          display="flex"
          alignItems="center"
          mt="16px"
          mb="8px"
          h="20%"
          data-testid="chatRoomsId"
        >
          {roomInformation.lastTimeOpenedByVoluntarie <
            roomInformation.messageTs && showDotNewMessage ? (
            <Image
              data-testid="greenDotTrue"
              src={'/icons/ellipse.svg'}
              m="5px"
              w="14px"
            />
          ) : (
            <Image
              data-testid="greenDotFalse"
              src=""
              pl="24px"
            />
          )}
          <Text
            textStyle="h3"
            fontSize="16px"
            data-testid="name"
          >
            {' '}
            {roomInformation.name || 'Usuarie: No disponible'}{' '}
            {roomInformation.comportamiento_suicida && <SelfInjuryTag />}
          </Text>
        </Box>
        <Box
          display="flex"
          textAlign="start"
          alignItems="center"
          ml="24px"
          fontSize="md"
        >
          <Text
            noOfLines={1}
            maxWidth="80%"
            textStyle="body1"
            mb="16px"
            mt="2px"
          >
            {roomInformation.lastMessage}
          </Text>
        </Box>
      </Box>
      <Divider
        style={{
          width: '90%',
          color: 'carbon.200',
          marginTop: '6px',
          marginBottom: '6px',
        }}
      />
    </>
  );
};

export default ChatRoom;
