import { useSelector } from 'react-redux';
import { Flex, Text, useDisclosure } from '@chakra-ui/react';
import ModalCloseChat from './ModalCloseChat';
import TMLinkButton from '../atoms/TMLinkButton';
import { showUsuarieStatus } from '../../utils/featureToggle';
import UsuarieCircleStatus from '../atoms/UsuarieCircleStatus/UsuarieCircleStatus';

const KebabMenu = () => {
  const currentRoom = useSelector((state) => state.room.currentRoom);
  const { name } = currentRoom;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        h="25%"
      >
        <Text
          pl="14px"
          mt="7px"
          textStyle="h3"
          display="flex"
          alignItems="center"
        >
          Conversación actual
        </Text>
      </Flex>
      <ModalCloseChat
        isOpen={isOpen}
        onClose={onClose}
        room={currentRoom}
      />
      <Flex
        justifyContent="space-between"
        alignItems="center"
        h="40%"
        borderColor="carbon.250"
        w="100%"
      >
        <Text
          textStyle="h1"
          display="flex"
          alignItems="center"
          pl="14px"
        >
          {' '}
          {showUsuarieStatus && <UsuarieCircleStatus status="inactive" />}
          {name || 'Usuarie: No Disponible'}
        </Text>
        <TMLinkButton
          bg="white"
          pr="3%"
          onClick={onOpen}
          textStyle="body2"
          data-testid="endConversationButton"
        >
          Finalizar Conversación
        </TMLinkButton>
      </Flex>
      <Text
        pl="5px"
        mt="4px"
        textStyle="h4"
        color="gray.500"
      >
        {currentRoom._idUsuarie ? 'ID: ' + currentRoom._idUsuarie : ''}
      </Text>
    </>
  );
};

export default KebabMenu;
