import { Container } from '@chakra-ui/react';

const ContainerCenterContent = (props) => {
  return (
    <Container
      {...props}
      centerContent
    >
      {props.children}
    </Container>
  );
};
export default ContainerCenterContent;
