import ContainerCenterContent from './ContainerCenterContent';

const RecaptchaRender = ({ showCaptcha = true }) => {
  const hiddeStyles = !showCaptcha ? { display: 'none' } : {};

  return (
    <ContainerCenterContent
      alignContent="center"
      textAlign="center"
      mt="1.25rem"
      style={hiddeStyles}
    >
      <div
        data-testid="recaptcha-container"
        id="recaptcha-container"
      ></div>
    </ContainerCenterContent>
  );
};

export default RecaptchaRender;
