import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState, useRef } from 'react';
import { getRooms } from '../../../../services/Rooms';
import { logErrorEvent } from '../../../../helpers/logAnalytics';
import ERROR_CATALOG from '../../../../helpers/errorCatalog';
import ChatRoom from '../../../molecules/ChatRoom';
import Loader from '../../../molecules/Loader';

const ChatRoomList = () => {
  const unsubscribeRef = useRef();
  const [rooms, setRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getClearRooms = (rooms) => {
    return rooms
      .filter((room) => Boolean(room.messageTs))
      .filter((room) => !isNaN(room.messageTs))
      .sort((room1, room2) => (room1.messageTs < room2.messageTs ? 1 : -1));
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const unsubscribe = await getRooms((rooms) => {
          const clearRooms = getClearRooms(rooms);
          setRooms(clearRooms);
        });
        unsubscribeRef.current = unsubscribe;
      } catch (error) {
        logErrorEvent({
          message: error.message,
          component_name: ERROR_CATALOG.VOLUNTARIES.GET_ROOMS,
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    return () => {
      const unsubscribe = unsubscribeRef.current;
      unsubscribe && unsubscribe();
    };
  }, []);

  return (
    <Box
      w="24%"
      h="100%"
    >
      {isLoading && (
        <Loader
          testId="loaderRooms"
          text="Cargando salas de chat"
          ariaLabel="Cargando salas de chat"
        />
      )}

      {!isLoading && (
        <>
          <Flex
            textAlign="start"
            px="32px"
            pt="16px"
            mb="8px"
            alignItems="center"
          >
            <Text
              textStyle="h3"
              mb="16px"
            >
              Chats
            </Text>
          </Flex>
          <VStack
            h="90%"
            borderColor="carbon.250"
            overflow="auto"
            spacing={0}
          >
            {rooms.length ? (
              rooms.map((room) => (
                <ChatRoom
                  key={`room-${room.uuid}`}
                  room={room}
                />
              ))
            ) : (
              <Text>No cuentas con chats activos</Text>
            )}
          </VStack>
        </>
      )}
    </Box>
  );
};

export default ChatRoomList;
