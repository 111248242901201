import CryptoJS from 'crypto-js';

const keyEncryption = process.env.REACT_APP_KEY_ENCRYPTION;
const keyEncryption2 = process.env.REACT_APP_KEY_ENCRYPTION2;

export const encryptedMessage = (message) => {
  return CryptoJS.AES.encrypt(
    JSON.stringify(message),
    keyEncryption
  ).toString();
};

export const decryptMessagesArray = (messagesArray) => {
  return messagesArray
    .map((message) => {
      const textToString = decryptText(message.text);
      const newMessage = { ...message, text: textToString };
      return newMessage;
    })
    .filter((message) => message.text);
};

export const decryptText = (message) => {
  if (!message) return '';
  const text = CryptoJS.AES.decrypt(message, keyEncryption);
  try {
    const textToString = text
      .toString(CryptoJS.enc.Utf8)
      .replaceAll('"', '')
      .trim();

    return textToString;
  } catch (e) {
    return '';
  }
};
export const decryptText2 = (message) => {
  if (!message) return '';
  const text = CryptoJS.AES.decrypt(message, keyEncryption2);
  try {
    const textToString = text
      .toString(CryptoJS.enc.Utf8)
      .replaceAll('"', '')
      .trim();

    return textToString;
  } catch (e) {
    return '';
  }
};
