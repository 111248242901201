import { HStack, Grid, GridItem } from '@chakra-ui/react';
import ChipButton from '../../atoms/ChipButton';

const ChipButtonList = ({ ...props }) => {
  const { group, list, getRadioProps, templateColumns, templateRows, onClick } =
    props || {};
  return (
    <HStack
      width="100%"
      {...group}
    >
      <Grid
        gap="4px"
        templateRows={templateRows}
        templateColumns={templateColumns}
        width="100%"
      >
        {list.map((item) => (
          <GridItem
            key={item.label}
            colSpan={item.colSpan}
          >
            <ChipButton
              key={item.label}
              py={item.py}
              minWidth={item.minWidth}
              px={item.px}
              onClick={() => onClick(item.label)}
              {...getRadioProps({ value: item.label })}
            >
              {item.label}
            </ChipButton>
          </GridItem>
        ))}
      </Grid>
    </HStack>
  );
};
export default ChipButtonList;
