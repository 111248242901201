import { useReducer, useEffect, useRef } from 'react';
import { getMessages } from '../../services/Messages';
import ERROR_CATALOG from '../../helpers/errorCatalog';
import { logErrorEvent } from '../../helpers/logAnalytics';
import reducer, { ACTIONS } from './reducer';
import { checkAuthState } from '../../services/FirebaseAuthService';

/**
 * Custom hook for requesting messages based on room ID, access token, and user type.
 *
 * @param {Object} options - Options for requesting messages.
 * @param {string} options.idRoom - The ID of the room for which messages are requested.
 * @param {string[]} options.accessToken - The access token(s) for authentication.
 * @param {string} options.userType - The type of user making the request ('voluntarie' or 'usuarie').
 *
 * @returns {Object} state - The state object containing loading, error, and data information.
 * @property {boolean} state.isLoading - Indicates whether messages are currently being loaded.
 * @property {boolean} state.hasError - Indicates whether an error occurred during the request.
 * @property {Array} state.data - The array of messages retrieved from the request.
 */
const useRequestMessages = ({ idRoom, accessToken, userType }) => {
  const unsubscribeRef = useRef();
  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    hasError: false,
    data: [],
  });

  const sendError = (error) => {
    dispatch({ type: ACTIONS.SET_ERROR, errorDetails: error.message });
    logErrorEvent({
      message: error.message,
      component_name:
        userType === 'voluntarie'
          ? ERROR_CATALOG.VOLUNTARIES.GET_MESSAGES
          : ERROR_CATALOG.USUARIES.GET_MESSAGES,
    });
  };

  useEffect(() => {
    if (!idRoom) return;
    const controller = new AbortController();
    const fetchData = async () => {
      dispatch({ type: ACTIONS.SET_LOADING });
      try {
        const signal = controller.signal;
        const unsubscribe = await getMessages(
          { idRoom, accessToken },
          (messages) => {
            dispatch({
              type: ACTIONS.SET_DATA,
              payload: messages,
            });
          },
          { signal }
        );

        unsubscribeRef.current = unsubscribe;
      } catch (error) {
        sendError(error);
      }
    };
    fetchData();
    return () => {
      const unsubscribe = unsubscribeRef.current;
      unsubscribe && unsubscribe();
      controller.abort();
    };
  }, [idRoom]);

  const handleLogout = () => {
    const unsubscribe = unsubscribeRef.current;
    unsubscribe && unsubscribe();
  };

  useEffect(() => {
    const unsubscribeAuthState = checkAuthState((user) => {
      if (!user) {
        handleLogout();
      }
    });
    return () => unsubscribeAuthState();
  }, []);

  return state;
};

export default useRequestMessages;
