import { Text } from '@chakra-ui/react';

const FormText = ({ label, value, datatestid }) => {
  return (
    <Text
      data-testid="label-text"
      margin={1}
      textStyle="body2"
      fontWeight="bold"
    >
      {label}:
      <Text
        data-testid={datatestid}
        textStyle="body2"
        as="span"
        className="display-block"
      >
        {value}
      </Text>
    </Text>
  );
};
export default FormText;
