import { WarningTwoIcon } from '@chakra-ui/icons';
import { Container, Flex, Text } from '@chakra-ui/react';

const ErrorTemplate = ({ text }) => {
  return (
    <Flex
      w="100%"
      direction="column"
      mt="7"
      mb="3"
      alignSelf="center"
    >
      <Container centerContent>
        <WarningTwoIcon
          w={10}
          h={10}
          color="red.500"
        />
      </Container>
      <Container
        maxW="md"
        mt="1.5rem"
        centerContent
      >
        <Text
          textStyle="body"
          textAlign="center"
        >
          {text ||
            'Lo sentimos, ha ocurrido un error. Vuelve a intentar mas tarde'}
        </Text>
      </Container>
    </Flex>
  );
};

export default ErrorTemplate;
