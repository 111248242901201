import { useState, useEffect } from 'react';
import { Box, Button, Text, Textarea, useToast } from '@chakra-ui/react';
import showAlert from '../../../utils/showAlert';

const UsuarieAttributes = ({ onSave, room }) => {
  const [usuarieAttribute, setUsuarieAttribute] = useState('');
  const toast = useToast();
  const [isSaving, setIsSaving] = useState(false);

  const handleOnChange = (event) => {
    setUsuarieAttribute(event.target.value);
  };

  const onSaveAttributes = async () => {
    setIsSaving(true);
    const status = await onSave(usuarieAttribute);
    if (status) {
      showAlert({
        toastFunc: toast,
        status: 'success',
        description: 'Los atributos se han guardado con éxito.',
      });
    } else {
      showAlert({
        toastFunc: toast,
        status: 'error',
        description: 'Ocurrió un error al guardar. Vuelve a intentarlo.',
      });
    }
    setIsSaving(false);
  };

  useEffect(() => {
    const normalizeText = room.attributes.replace(/\\\\+/g, '\\');
    const text = normalizeText.replace(/\\n/g, '\n');
    setUsuarieAttribute(text);
  }, [room]);

  return (
    <>
      <Box
        display="flex"
        height="100%"
        flexDirection="column"
      >
        <Text
          textStyle="body2"
          color="carbon.500"
          h="5%"
        >
          Resumen de la conversación:
        </Text>
        <Box
          h="80%"
          pt={2}
        >
          <Textarea
            border="1px"
            borderColor="carbon.400"
            resize="none"
            value={usuarieAttribute}
            onChange={handleOnChange}
            color="carbon.500"
            fontSize="14px"
            h="100%"
            focusBorderColor="aqua.500"
          />
        </Box>
        <Box
          h="15%"
          alignItems="end"
          display="flex"
        >
          <Button
            isLoading={isSaving}
            h="80%"
            maxHeight="3rem"
            variant="primary"
            bgColor="mora.500"
            textColor="#FFFFFF"
            w="100%"
            onClick={onSaveAttributes}
            isDisabled={usuarieAttribute.length === 0}
          >
            Guardar
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UsuarieAttributes;
