import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Box,
  Checkbox,
  Container,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VisuallyHidden,
  useDisclosure,
} from '@chakra-ui/react';
import TMLinkButton from '../../../components/atoms/TMLinkButton';
import { useSelector, useDispatch } from 'react-redux';
import { roomActions } from '../../../store/Slices/room';
import { getRoomByUsuarieId } from '../../../services/Rooms';
import ModalTermsAndConditions from '../../../components/molecules/ModalTermsAndConditions';
import FormLabelInput from '../../../components/atoms/FormLabelInput';
import ChipSectionsContainer from '../../../components/organisms/usuaries/ChipSectionsContainer';
import { FORCE_LOGIN } from '../../../utils/featureToggle';
import { saveUsuarie } from '../../../services/Usuarie';
import { validateFields } from './utils';

const UsuarieRegisterPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const phoneNumber = localStorage.getItem('phoneNumber');
  const uuidUsuarie = useSelector((state) => state.user.uuid);

  useEffect(() => {
    if (!uuidUsuarie && FORCE_LOGIN) {
      navigate('/usuaries/auth');
    }
  }, []);

  const handleUsuarieForm = async () => {
    // TODO validar que exista un cupo para ingresar al chat (max 10 chat por voluntarie)
    const usuarie = {
      id: uuidUsuarie,
      name,
      age,
      commune,
      gender,
      bioSex,
      sexualOrientation,
      disability,
      phone: phoneNumber,
    };
    saveUsuarie(usuarie);
    const room = await getRoomByUsuarieId(uuidUsuarie);
    dispatch(roomActions.setRoom(room));
    navigate('/usuaries/chat');
  };

  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [commune, setCommune] = useState('');

  const [gender, setGender] = useState('');
  const [bioSex, setBioSex] = useState('');
  const [sexualOrientation, setSexualOrientation] = useState('');

  const [disability, setDisability] = useState(false);

  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const [openModal, setModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');

  const showModal = (e) => {
    if (e.target.name === 'identidad-genero') {
      setModalTitle('Identidad de género:');
      setModalDescription(
        'Se refiere a la vivencia interna e individual del género, tal como cada persona la siente profundamente, la cual podría corresponder o no con el sexo asignado al nacer, incluyendo la vivencia personal del cuerpo.'
      );
    }

    if (e.target.name === 'sexo-biologico') {
      setModalTitle('Sexo biológico:');
      setModalDescription(
        'Características biológicas (genéticas, endocrinas y anatómicas) empleadas para otorgarle a una persona la definición de macho, hembra, intersexual.'
      );
    }

    if (e.target.name === 'orientacion-sexoafectiva') {
      setModalTitle('Orientación sexoafectiva:');
      setModalDescription(
        'Se refiere a la atracción emocional, afectiva y sexual hacia otras personas.'
      );
    }
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  return (
    <Flex
      width="100%"
      direction="column"
      marginTop="32px"
    >
      <Container centerContent>
        <Image
          src={'/images/logo-todo-mejora-icon.svg'}
          alt={'Logo corazón Hora Segura'}
          boxSize={'62px'}
          mb={'24px'}
        />
        <Box
          textAlign="left"
          width="100%"
        >
          <Text
            textStyle="h2"
            color="mora.500"
            marginBottom="6px"
          >
            Cuéntanos de ti
          </Text>
          <Text
            color="#161616"
            textStyle="body2"
            width="100%"
            marginBottom="32px"
          >
            Conocerte nos ayudará a darte una mejor atención en el futuro.
          </Text>
        </Box>
        <FormLabelInput
          inputProps={{ 'data-testid': 'inputApodo' }}
          boxProps={{ w: '100%' }}
          labelText={'Nombre / Apodo'}
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        <Flex
          width="100%"
          gap="18px"
          marginTop={6}
          marginBottom="32px"
        >
          <FormLabelInput
            inputProps={{ 'data-testid': 'inputEdad', required: true }}
            boxProps={{ w: '20%' }}
            labelText={'Edad'}
            value={age}
            onChange={(event) => {
              setAge(event.target.value);
            }}
          />
          <FormLabelInput
            inputProps={{ 'data-testid': 'inputComuna', required: true }}
            boxProps={{ w: '80%' }}
            labelText={'Comuna'}
            value={commune}
            onChange={(event) => {
              setCommune(event.target.value);
            }}
          />
        </Flex>
        <ChipSectionsContainer
          showModal={showModal}
          handlerGender={setGender}
          handlerBioSex={setBioSex}
          handlerSexualOrientation={setSexualOrientation}
        />
        <Box
          mt="1rem"
          width="100%"
        >
          <Flex direction="column">
            <VisuallyHidden>
              <Checkbox
                spacing="0.5625rem"
                lineHeight="1.125rem"
                fontSize="0.875rem"
                fontStyle="normal"
                fontWeight="400"
                colorScheme="mora.500"
                onChange={() => {
                  setDisability(!disability);
                }}
              >
                <Text
                  color="#161616"
                  lineHeight="1.25rem"
                  fontSize="0.875rem"
                  fontStyle="normal"
                  fontWeight="400"
                >
                  Soy una persona con discapacidad
                </Text>
              </Checkbox>
            </VisuallyHidden>
            <Checkbox
              data-testid="termsAndConditions"
              colorScheme="purple"
              spacing="0.5625rem"
              mt="1.25rem"
              onChange={(event) => {
                setAcceptedTerms(event.target.checked);
              }}
            >
              <Text
                color="#161616"
                lineHeight="1.25rem"
                fontSize="0.875rem"
                fontStyle="normal"
                fontWeight="400"
              >
                Acepto los{' '}
                <TMLinkButton onClick={onOpen}>
                  Términos y condiciones
                </TMLinkButton>
                <ModalTermsAndConditions
                  isOpen={isOpen}
                  onClose={onClose}
                />
              </Text>
            </Checkbox>
          </Flex>
        </Box>
        <Box
          justifyContent="start"
          mt="1.5rem"
          mb="2.65rem"
          width="100%"
        >
          <Button
            h="2.875rem"
            width="100%"
            variant="primary"
            isDisabled={
              !validateFields(
                name,
                age,
                commune,
                gender,
                bioSex,
                sexualOrientation,
                acceptedTerms
              )
            }
            onClick={handleUsuarieForm}
            data-testid="startChatting"
          >
            Comenzar a chatear
          </Button>
        </Box>
      </Container>

      {openModal ? (
        <Modal
          isOpen={openModal}
          onClose={closeModal}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Text
                color="mora.500"
                mt="1.5rem"
                textStyle="h1"
                mb="0.875rem"
              >
                {modalTitle}
              </Text>
              <Text
                color="#161616"
                textStyle="body2"
                mb="2.65rem"
              >
                {modalDescription}
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : null}
    </Flex>
  );
};

export default UsuarieRegisterPage;
