import { Link } from '@chakra-ui/react';

const TMLinkButton = (props) => {
  return (
    <Link
      fontStyle="normal"
      fontWeight="400"
      fontSize="0.875rem"
      color="#161616"
      target="_blank"
      textDecoration="underline"
      {...props}
    ></Link>
  );
};

export default TMLinkButton;
