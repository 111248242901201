export const listGender = [
  {
    label: 'Mujer',
    py: '12px',
    px: '8px',
    minWidth: 'auto',
    colSpan: 1,
  },
  {
    label: 'No binarie',
    py: '12px',
    px: '8px',
    minWidth: 'auto',
    colSpan: 2,
  },
  {
    label: 'Hombre',
    py: '12px',
    px: '8px',
    minWidth: 'auto',
    colSpan: 1,
  },
  {
    label: 'Estoy descubriéndolo',
    py: '12px',
    px: '8px',
    minWidth: 'auto',
    colSpan: 2,
  },
  {
    label: 'No quiero responder',
    py: '12px',
    px: '8px',
    minWidth: 'auto',
    colSpan: 2,
  },
];

export const listBioSex = [
  { label: 'Hembra', py: '12px', px: '8px', minWidth: '105px', colSpan: 1 },
  {
    label: 'Intersexual',
    py: '12px',
    px: '8px',
    minWidth: '105px',
    colSpan: 1,
  },
  { label: 'Macho', py: '12px', px: '8px', minWidth: '105px', colSpan: 1 },
  {
    label: 'No quiero responder',
    py: '12px',
    px: '8px',
    minWidth: '105px',
    colSpan: 2,
  },
];

export const listSexAffectiveOrientation = [
  { label: 'Hétero', py: '12px', px: '8px', colSpan: 1 },
  {
    label: 'Bi / Pan',
    py: '12px',
    px: '8px',
    colSpan: 1,
  },
  {
    label: 'Lesbiana / Gay',
    py: '12px',
    px: '8px',
    colSpan: 2,
  },
  {
    label: 'Estoy descubriéndolo',
    py: '12px',
    px: '8px',
    colSpan: 2,
  },
  {
    label: 'No quiero responder',
    py: '12px',
    px: '8px',
    colSpan: 2,
  },
];
