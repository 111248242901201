import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Link,
  Text,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { fetchToggles } from '../../../services/FirebaseRemoteConfig';
import { logEventAnalytics } from '../../../helpers/logAnalytics';
import isWorkingHours from './workingHours';

const ModalOutOfWorkingHours = () => {
  const [closeButtonModalEnable, setCloseButtonModalEnable] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const onClose = () => {
    setIsOpen(false);
  };

  const checkWorkingHours = () => {
    const date = new Date();
    const day = date.getDay();
    const hour = date.getHours();
    if (isWorkingHours(day, hour)) {
      setIsOpen(false);
      return true;
    }
    setIsOpen(true);
    logEventAnalytics('usuarie fuera de horario');
    return false;
  };

  useEffect(() => {
    checkWorkingHours();
    fetchToggles().then((res) => {
      const showModalCloseBtn = res?.REACT_APP_MODAL_CLOSE_BUTTON;
      if (!showModalCloseBtn) return;
      setCloseButtonModalEnable(showModalCloseBtn.asBoolean());
    });
  }, []);

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      data-testid="modal-test"
    >
      <ModalOverlay />
      <ModalContent
        w="80%"
        alignSelf="center"
        data-testid="modal-content-test"
      >
        <ModalHeader
          fontSize="h4"
          color="mora.500"
          lineHeight="22px"
          textAlign="left"
          fontWeight="700"
        >
          Lamentamos no poder conversar contigo en este momento.
        </ModalHeader>
        {closeButtonModalEnable && (
          <ModalCloseButton data-testid="modal-close-button" />
        )}
        <ModalBody
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          <Text
            w="90%"
            textAlign="left"
            fontSize="sm"
            lineHeight="18px"
            mb="2"
          >
            Puedes hablar con nosotres en los siguientes horarios:{' '}
          </Text>{' '}
          <Text
            w="90%"
            textAlign="left"
            fontSize="sm"
          >
            Lunes y Martes de
          </Text>{' '}
          <Text
            w="90%"
            textAlign="left"
            fontSize="sm"
            fontWeight="bold"
          >
            {' '}
            13:00 a 14:00 y 18:00 a 00:00hrs.{' '}
          </Text>{' '}
          <Text
            w="90%"
            textAlign="left"
            fontSize="sm"
            color="carbon.400"
          >
            Horario de Chile
          </Text>{' '}
          <Text
            w="90%"
            textAlign="left"
            fontSize="sm"
          >
            Miercoles a Viernes de
          </Text>{' '}
          <Text
            w="90%"
            textAlign="left"
            fontSize="sm"
            fontWeight="bold"
          >
            {' '}
            13:00 a 14:00 y 17:00 a 00:00hrs.{' '}
          </Text>{' '}
          <Text
            w="90%"
            textAlign="left"
            fontSize="sm"
            color="carbon.400"
          >
            Horario de Chile
          </Text>{' '}
          <Text
            w="90%"
            textAlign="left"
            fontSize="sm"
            mt="2"
          >
            Sábados y Domingos de
          </Text>{' '}
          <Text
            w="90%"
            textAlign="left"
            fontSize="sm"
            fontWeight="bold"
          >
            {' '}
            13:00 a 14:00 y 19:00 a 23:00hrs.{' '}
          </Text>{' '}
          <Text
            w="90%"
            textAlign="left"
            fontSize="sm"
            color="carbon.400"
          >
            Horario de Chile
          </Text>{' '}
          <Text
            w="90%"
            textAlign="left"
            pt="10%"
            pb="10%"
            fontSize="sm"
            lineHeight="18px"
          >
            {' '}
            Por el momento te recomendamos el siguiente{' '}
            <Link
              onClick={() => {
                logEventAnalytics('Clicks en material de apoyo');
              }}
              color="#1180FF"
              href="https://www.youtube.com/watch?v=9FltZ5harNs&list=PLq9Q52ZNa5csx3FlVCsMDfVhnRouWFQg2"
              isExternal
            >
              <u>material de apoyo</u>
            </Link>{' '}
            y otra línea de ayuda e información:
          </Text>
          <Text
            color="mora.500"
            w="90%"
            textAlign="left"
            fontSize="h4"
            fontWeight="700"
            mb="1"
          >
            Salud Responde
          </Text>{' '}
          <Text
            w="90%"
            textAlign="left"
            fontSize="sm"
            lineHeight="18px"
            mb="3"
          >
            Télefono disponible 24/7 para asistencia, orientación y apoyo en
            temáticas de salud. <br />
            <b>Contáctate al 600 360 7777 </b>
          </Text>{' '}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalOutOfWorkingHours;
