import { useEffect, useState } from 'react';
import { Box, Flex, VStack } from '@chakra-ui/react';
import HeaderComponent from '../../../components/molecules/HeaderComponent/HeaderComponent';
import VoluntarieChat from '../../../components/templates/VoluntarieChat';
import ChatRoomList from '../../../components/organisms/voluntaries/ChatRoomList';
import ChatInputBar from '../../../components/molecules/ChatInputBar';
import UserData from '../../../components/organisms/voluntaries/UserData/UserData';
import VoluntarieGreetingMessage from '../../../components/molecules/VoluntarieGreetingMessage';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkAuthState } from '../../../services/FirebaseAuthService';
import { userActions } from '../../../store/Slices/users';
import KebabMenu from '../../../components/molecules/KebabMenu';

const VoluntarieOverviewPage = () => {
  const [activeChatState, setActiveChat] = useState(false);
  const currentRoom = useSelector((state) => state.room.currentRoom);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const verifyActiveChats = () => {
    if (currentRoom[0] != '0000x') {
      setActiveChat(true);
    }
  };

  useEffect(() => {
    verifyActiveChats();
  }, [currentRoom]);

  useEffect(() => {
    const unsubscribe = checkAuthState((user) => {
      if (user) {
        dispatch(
          userActions.setUser({
            userType: 'voluntarie',
            uuid: user.auth.currentUser.uid,
            token: user.auth.currentUser.accessToken,
            displayName: user.auth.currentUser.email,
          })
        );
      } else {
        navigate('/voluntaries/auth');
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      w="100vw"
      h="100vh"
    >
      <Box
        border="1px"
        borderColor="carbon.100"
        w="95%"
        h="95%"
      >
        <Box
          h="10%"
          w="100%"
        >
          <HeaderComponent
            isVoluntarie={true}
            showDownloadButtons={true}
          />
        </Box>

        <Box
          display="flex"
          w="100%"
          h="90%"
        >
          <>
            <ChatRoomList />
            {activeChatState ? (
              <>
                <VStack
                  spacing="0"
                  w="50%"
                  h="100%"
                  borderRight="1px"
                  borderLeft="1px"
                  borderColor="carbon.100"
                >
                  <Box
                    minHeight={'15%'}
                    w="100%"
                  >
                    <KebabMenu />
                  </Box>

                  <Box
                    bg="background"
                    h="80%"
                    w="100%"
                    overflow="auto"
                    p="4"
                    borderY="1px solid"
                    borderColor="carbon.100"
                  >
                    <VoluntarieChat />
                  </Box>

                  <Box
                    h={'10%'}
                    w={'100%'}
                  >
                    <ChatInputBar />
                  </Box>
                </VStack>
                <UserData />
              </>
            ) : (
              <VoluntarieGreetingMessage />
            )}
          </>
        </Box>
      </Box>
    </Flex>
  );
};

export default VoluntarieOverviewPage;
