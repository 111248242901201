import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { useRef, useEffect } from 'react';

const Loader = ({ text, ariaLabel, type = 'vertical', testId = 'loader' }) => {
  const loaderRef = useRef();

  useEffect(() => {
    if (loaderRef.current) {
      loaderRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, []);

  return (
    <>
      {type === 'vertical' ? (
        <Box
          align="center"
          mt="2rem"
          data-testid={testId}
        >
          <Spinner
            label={ariaLabel || text}
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="sunny.500"
            size="xl"
          />
          <Text
            ref={loaderRef}
            aria-hidden
          >
            {text}
          </Text>
        </Box>
      ) : (
        <Flex
          mt="2rem"
          alignItems="center"
          justifyContent="center"
          data-testid={testId}
        >
          <Text
            fontSize="sm"
            aria-hidden
            ref={loaderRef}
          >
            {text}
          </Text>
          <Spinner
            ml="0.5rem"
            size="md"
            label={ariaLabel || text}
            thickness="2px"
            speed="0.65s"
            emptyColor="gray.200"
            color="sunny.500"
          />
        </Flex>
      )}
    </>
  );
};

export default Loader;
