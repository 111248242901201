import { Image } from '@chakra-ui/react';

const IconPhone = () => {
  return (
    <Image
      src={'/images/smartphone.svg'}
      alt={'Ícono de Celular'}
      w={'1rem'}
    />
  );
};

export default IconPhone;
