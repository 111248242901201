import axios from 'axios';

const REACT_APP_BASE_URL_FUNCTION = process.env.REACT_APP_BASE_URL_FUNCTION;

const axiosFunctionInstance = axios.create({
  baseURL: REACT_APP_BASE_URL_FUNCTION,
});

axiosFunctionInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log('entro al if :)');
      console.log('call the refresh token api here');
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

export default axiosFunctionInstance;
