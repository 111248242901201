import { Box, Flex, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useRef, useEffect } from 'react';
import './index.css';

const ChatBubble = ({ content, timestamp, isSentMessage = true }) => {
  const messageRef = useRef();

  const stylesOfMessage = {
    backgroundColor: isSentMessage ? 'mora.500' : '#ffffff',
    color: isSentMessage ? '#ffffff' : '#333333',
    maxWidth: '65%',
    minWidth: 'auto',
  };

  useEffect(() => {
    if (messageRef) {
      messageRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, []);

  return (
    <Flex
      data-testid="chatBubble"
      alignItems={isSentMessage ? 'end' : 'start'}
      direction="column"
    >
      <Box
        {...stylesOfMessage}
        className={`messageBox ${
          isSentMessage ? 'sentMessage' : 'receivedMessage'
        }`}
        data-testid="chatBubble-box"
      >
        <Text
          className="messageBoxText"
          ref={messageRef}
        >
          {content}
          {timestamp && (
            <span
              className="messageBoxTime"
              data-testid="message-time"
            >
              {moment(timestamp).format('kk:mm')}
            </span>
          )}
        </Text>
      </Box>
    </Flex>
  );
};

export default ChatBubble;
