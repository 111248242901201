import axios from 'axios';
import { doc, updateDoc, addDoc, collection } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';

const ENCRYPT_ATTRIBUTES_URL = process.env.REACT_APP_ENCRYPT_ATTRIBUTES_URL;

export const saveUsuarie = async ({
  id,
  name,
  age,
  commune,
  gender,
  bioSex,
  sexualOrientation,
  disability,
  phone,
}) => {
  const data = {
    _idUsuarie: id,
    name,
    age,
    commune,
    genderIdentity: gender,
    bioSex,
    sexualOrientation,
    discapacidad: disability,
    phone,
    session: 0,
  };
  return await addDoc(collection(firestore, 'usuaries'), data);
};

export const saveUsuarieAttributeInRoom = async (text, roomId) => {
  try {
    const response = await axios.post(
      `${ENCRYPT_ATTRIBUTES_URL}/${roomId}`,
      { attributes: text },
      {
        headers: {
          Authorization: 'Bearer' + 'test',
          'Content-Type': 'application/json',
        },
      }
    );

    return response.status === 200;
  } catch (error) {
    console.error('Error saving usuarie attribute:', error.message);
    return false;
  }
};

export const saveUsuarieTagsInRoom = async (tags, roomId) => {
  const dbRef = doc(firestore, 'usuaries', roomId);
  try {
    await updateDoc(dbRef, tags);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
