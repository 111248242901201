import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
} from '@chakra-ui/react';

const ErrorAlert = ({ message }) => {
  return (
    <Alert status="error">
      <AlertIcon />
      <Box>
        <AlertTitle>{message}</AlertTitle>
      </Box>
      <CloseButton
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
      />
    </Alert>
  );
};

export default ErrorAlert;
