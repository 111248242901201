import ChipGenderContainer from '../../molecules/ChipButtonSection/ChipGenderContainer';
import ChipBioSexContainer from '../../molecules/ChipButtonSection/ChipBioSexContainer';
import ChipSexualOrientation from '../../molecules/ChipButtonSection/ChipSexualOrientation';

const ChipSectionsContainer = ({
  showModal,
  handlerGender,
  handlerBioSex,
  handlerSexualOrientation,
}) => {
  return (
    <>
      <ChipGenderContainer
        onChange={handlerGender}
        showModal={showModal}
      />
      <ChipBioSexContainer
        onChange={handlerBioSex}
        showModal={showModal}
      />
      <ChipSexualOrientation
        onChange={handlerSexualOrientation}
        showModal={showModal}
      />
    </>
  );
};
export default ChipSectionsContainer;
