import { Button, Flex, Image, Text, useRadioGroup } from '@chakra-ui/react';
import { listSexAffectiveOrientation } from './dataToRender';
import ChipButtonList from './ChipButtonList';

const ChipSexualOrientation = ({ onChange, showModal }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'test',
    defaultValue: 'two',
  });

  const group = getRootProps();
  const ChipButtonListProps = {
    list: listSexAffectiveOrientation,
    group,
    getRadioProps,
    templateColumns: 'repeat(4, 1fr)',
    templateRows: 'repeat(2, 1fr)',
    onClick: onChange,
  };
  return (
    <>
      <Flex
        direction="row"
        alignItems="left"
        width="100%"
        mt="16px"
        marginBottom="8px"
      >
        <Text
          color="mora.500"
          textStyle="h5"
          marginRight="8px"
        >
          ¿Cuál es tu orientación sexoafectiva? *
        </Text>
        <Button
          size="xs"
          backgroundColor="white"
          onClick={showModal}
        >
          <Image
            src="/icons/info.svg"
            alt="Ícono de información"
            name="orientacion-sexoafectiva"
          />
        </Button>
      </Flex>
      <ChipButtonList {...ChipButtonListProps} />
    </>
  );
};

export default ChipSexualOrientation;
