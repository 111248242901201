import { Flex } from '@chakra-ui/react';
import ContainerCenterContent from '../atoms/ContainerCenterContent';
import IconApp from '../atoms/IconApp';
import TMTitleLoginUsuarie from '../atoms/TMTitleLoginUsuarie';
import ParagraphLoginUsuarie from '../atoms/ParagraphLoginUsuarie';

const UsuarieLoginHeader = () => {
  return (
    <Flex
      w="100%"
      direction="column"
      mt="1.875rem"
    >
      <ContainerCenterContent>
        <IconApp />
      </ContainerCenterContent>
      <ContainerCenterContent mt="1.75rem">
        <TMTitleLoginUsuarie>Ingresa tu número de teléfono</TMTitleLoginUsuarie>
      </ContainerCenterContent>
      <ContainerCenterContent
        maxW="md"
        mt="1rem"
      >
        <ParagraphLoginUsuarie>
          Enviaremos un sms con el código para ingresar
        </ParagraphLoginUsuarie>
      </ContainerCenterContent>
    </Flex>
  );
};

export default UsuarieLoginHeader;
