const togglesConfig = ({ disabledRecaptcha }) => ({
  shouldDisabledRecaptcha:
    process.env.REACT_APP_DATABASE_ENVIRONMENTE !== 'prod' && disabledRecaptcha,
});
export default togglesConfig;

const followLoginV02 = process.env.REACT_APP_LOGIN_V02 === 'true';

const showUsuarieStatus = process.env.REACT_APP_SHOW_USUARIE_STATUS === 'true';

const FORCE_LOGIN = process.env.NODE_ENV !== 'development';
const ENABLE_MODAL_OF_WORKING = process.env.NODE_ENV !== 'development';

export {
  FORCE_LOGIN,
  ENABLE_MODAL_OF_WORKING,
  followLoginV02,
  showUsuarieStatus,
};
