import { Box, SimpleGrid } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import FormText from '../../atoms/FormText/FormText';

const UserInfo = () => {
  const currentRoom = useSelector((state) => state.room.currentRoom);
  const fields = [
    {
      label: 'Nombre/Apodo',
      value: currentRoom?.name,
      id: 'nickname',
    },
    {
      label: 'Comuna',
      value: currentRoom.commune,
      id: 'town',
    },
    {
      label: 'Sexo biológico',
      value: currentRoom.bioSex,
      id: 'sex',
    },
    {
      label: 'Edad',
      value: currentRoom.age,
      id: 'age',
    },
    {
      label: 'Identidad de Género',
      value: currentRoom.genderIdentity,
      id: 'gender',
    },
    {
      label: 'Orientación Sexoafectiva',
      value: currentRoom.sexualOrientation,
      id: 'orientation',
    },
  ];
  return (
    <SimpleGrid
      data-testid="simpleGrid"
      minChildWidth="150px"
      columns={[1, 1, 2]}
      spacing="4px"
      paddingY="10px"
      height="25%"
      overflowY="scroll"
    >
      {fields.map(({ label, value, id }) => (
        <Box
          height="50px"
          minHeight="40px"
          key={label}
        >
          <FormText
            label={label}
            value={value}
            datatestid={id}
          ></FormText>
        </Box>
      ))}
    </SimpleGrid>
  );
};

export default UserInfo;
