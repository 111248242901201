import { Badge } from '@chakra-ui/react';

const SelfInjuryTag = () => {
  return (
    <Badge
      ml={1}
      colorScheme="red"
      background="#FFD6CA"
      color="#D82729"
      px={2}
    >
      Urgente
    </Badge>
  );
};

export default SelfInjuryTag;
