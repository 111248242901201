import { Text } from '@chakra-ui/react';
const ParagraphLoginUsuarie = ({ children }) => {
  return (
    <Text
      color="#161616"
      lineHeight="1.25rem"
      textStyle="body2"
    >
      {children}
    </Text>
  );
};

export default ParagraphLoginUsuarie;
