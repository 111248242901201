import { firestore } from '../firebaseConfig';
import { decryptText, decryptText2 } from '../utils/messageEncryption';
import {
  doc,
  collection,
  limit,
  where,
  getDocs,
  query,
  onSnapshot,
  updateDoc,
  increment,
} from 'firebase/firestore';

export const getRoomByUsuarieId = async (usuarieId) => {
  const dbRef = collection(firestore, 'usuaries');
  const roomQuery = query(
    dbRef,
    where('_idUsuarie', '==', usuarieId),
    limit(1)
  );

  let roomData;

  try {
    const roomResult = await getDocs(roomQuery);
    roomResult.forEach((doc) => {
      const roomKey = doc.id;
      if (!roomKey) return null;
      roomData = doc.data();
      roomData.attributes = decryptText2(roomData.attributes);
      roomData.uuid = roomKey;
      if (roomData.lastMessage) {
        roomData.lastMessage = decryptText(roomData.lastMessage);
      }
    });
    return roomData;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getRoomSnapshot = async (roomId, cb) => {
  const dbRef = doc(firestore, `usuaries/${roomId}`);

  onSnapshot(dbRef, (querySnapshot) => {
    const room = querySnapshot.data();
    if (!querySnapshot.exists()) {
      return cb(null);
    }
    const roomData = { ...room };
    roomData.uuid = querySnapshot.id;
    if (roomData.lastMessage) {
      roomData.lastMessage = decryptText(roomData.lastMessage);
    }
    cb(roomData);
  });
};

export const getRooms = (callback) => {
  const roomRef = collection(firestore, 'usuaries');

  return new Promise((resolve, reject) => {
    const mapRooms = (querySnapshot) => {
      const rooms = [];
      querySnapshot.forEach((doc) => {
        const info = doc.data();
        info.uuid = doc.id;
        if (info.lastMessage) {
          info.lastMessage = decryptText(info.lastMessage);
        }
        info.attributes = decryptText2(info.attributes);
        info.sessionCounter = info.sessionCounter || 0;
        rooms.push(info);
      });
      callback(rooms);
      resolve(unsubscribe);
    };

    const unsubscribe = onSnapshot(roomRef, mapRooms, (error) => {
      console.error('Error fetching messages from Firestore:', error.message);
      reject(error);
    });
  });
};

export const closeChat = async (roomId) => {
  const dbRef = doc(firestore, 'usuaries', roomId);
  await updateDoc(dbRef, {
    sessionCounter: increment(1),
  });
};
