import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';
import { Center } from '@chakra-ui/react';

const ChatDate = ({ timestamp, index, chats }) => {
  const firstMessageOfTheDay =
    index == 0 ||
    moment(chats[index - 1].ts).format('YYYYMMDD') !=
      moment(chats[index].ts).format('YYYYMMDD');

  const differenceBetweenMessageHourAndNow = moment(timestamp)
    .startOf('day')
    .diff(moment().startOf('day').subtract(1, 'days'));

  const formatDayDate = () => {
    if (differenceBetweenMessageHourAndNow > 0) {
      return 'Hoy';
    }
    if (differenceBetweenMessageHourAndNow == 0) {
      return 'Ayer';
    }
    return <Moment format="DD/MM/YYYY">{timestamp}</Moment>;
  };

  return (
    firstMessageOfTheDay && (
      <Center
        p={[4]}
        color="carbon.400"
        data-testid="dateGeneral"
      >
        {formatDayDate()}
      </Center>
    )
  );
};
export default ChatDate;
