import { Component } from 'react';
import { logErrorEvent } from '../../helpers/logAnalytics';
import ErrorTemplate from '../../components/templates/ErrorTemplate';
import PropTypes from 'prop-types';

const componentNameRegex = /at\s+(\S+?)\s*\(/;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, message: '' };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const match = errorInfo.componentStack.match(componentNameRegex);
    const componentName = match ? match[1] : 'UnknownComponent';
    logErrorEvent({
      message: error.message,
      component_name: componentName,
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorTemplate />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
