import { createSlice } from '@reduxjs/toolkit';

const initialUsuarieState = {
  uuid: '',
  token: '',
  userType: '',
  displayName: '',
};
const userSlice = createSlice({
  name: 'user',
  initialState: initialUsuarieState,
  reducers: {
    setUser(state, action) {
      return {
        ...state,
        uuid: action.payload.uuid,
        token: action.payload.token,
        userType: action.payload.userType,
        displayName: action.payload.displayName,
      };
    },
  },
});

export const userActions = userSlice.actions;
export const userReducers = userSlice.reducer;
export default userSlice;
