import { onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { encryptedMessage } from '../utils/messageEncryption';
import axiosFunctionInstance from './http';

export const sendMessage = async ({
  text,
  senderId,
  roomId,
  sessionCounter = 0,
  accessToken,
  closeSession = false,
}) => {
  const message = {
    text,
    senderId,
    roomId,
    session: sessionCounter,
    ...(closeSession && { closeSession }),
  };

  const { data } = await axiosFunctionInstance.post('/sendMessage', message, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
  return data;
};

export const updateLastMessageInUsuarie = async (
  message,
  senderId,
  timestamp
) => {
  const dbRef = doc(firestore, 'usuaries', senderId);
  await updateDoc(dbRef, {
    lastMessage: encryptedMessage(message),
    messageTs: timestamp,
  });
};

export const updateLastTimeOpenedMessageByVoluntarie = async (
  roomId,
  timestamp
) => {
  const dbRef = doc(firestore, 'usuaries', roomId);
  await updateDoc(dbRef, {
    lastTimeOpenedByVoluntarie: timestamp,
  });
};

export const sendUsuarieMessage = async ({
  text,
  senderId,
  sessionCounter,
  accessToken,
  usuarieUuid,
}) => {
  const timestamp = Date.now();
  const message = {
    text,
    senderId,
    roomId: senderId,
    sessionCounter,
    accessToken,
  };
  await sendMessage(message);
  await updateLastMessageInUsuarie(text, usuarieUuid, timestamp);
};

export const sendLastTimeOpenedMessageByVoluntarie = async (roomId) => {
  const timestamp = Date.now();
  await updateLastTimeOpenedMessageByVoluntarie(roomId, timestamp);
};

export const sendVoluntarieMessage = async ({
  text,
  senderId,
  roomId,
  sessionCounter,
  accessToken,
  closeSession = false,
}) => {
  const message = {
    text,
    senderId,
    roomId,
    sessionCounter,
    accessToken,
    closeSession,
  };

  await sendMessage(message);
};

export const getMessages = ({ idRoom, accessToken }, callback, config) => {
  const dbRef = doc(firestore, `chatV02/${idRoom}`);

  return new Promise((resolve, reject) => {
    const fetchData = async () => {
      try {
        const { data } = await axiosFunctionInstance.get(
          `/getMessage/${idRoom}`,
          {
            headers: {
              Authorization: 'Bearer' + accessToken,
            },
            ...config,
          }
        );

        callback(data.chats);
        resolve(unsubscribe);
      } catch (error) {
        reject(error);
      }
    };

    const unsubscribe = onSnapshot(dbRef, fetchData, (error) => {
      console.error('Error fetching messages from Firestore:', error.message);
      reject(error);
    });
  });
};
