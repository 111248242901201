import { Text } from '@chakra-ui/react';
import { CL_PRETTIER } from '../../utils/countryPhoneCode';

const TextPhone = () => {
  return (
    <Text
      lineHeight="1.375rem"
      fontSize="1rem"
      fontStyle="normal"
      fontWeight="400"
      ml="0.3275rem"
      mr="0.3125rem"
    >
      {CL_PRETTIER}
    </Text>
  );
};

export default TextPhone;
