import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { downloadData } from '../../services/Reporting';
import showAlert from '../../utils/showAlert';
import Loader from './Loader';
import { logErrorEvent } from '../../helpers/logAnalytics';
import ERROR_CATALOG from '../../helpers/errorCatalog';

const ModalDownloadData = ({ isOpen, onClose }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState({
    date_start: '',
    date_end: '',
  });
  const [isDisabled, setIsDisabled] = useState(true);

  const handleChange = (event) => {
    setDates({ ...dates, [event.target.name]: event.target.value });
  };

  const handleClick = async () => {
    setLoading(true);
    try {
      await downloadData(dates.date_start, dates.date_end);
      showAlert({
        toastFunc: toast,
        status: 'success',
        description: 'Reporte descargado correctamente',
      });
    } catch (error) {
      logErrorEvent({
        message: error.message,
        component_name: ERROR_CATALOG.VOLUNTARIES.DOWNLOAD_DATA,
      });
      showAlert({
        toastFunc: toast,
        status: 'error',
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setIsDisabled(
      dates.date_start === '' ||
        dates.date_end === '' ||
        dates.date_start > dates.date_end
    );
  }, [dates]);

  return (
    <>
      <Modal
        onClose={onClose}
        size={'md'}
        isOpen={isOpen}
      >
        <ModalOverlay />
        <ModalContent
          px="2%"
          pt="1%"
        >
          <ModalHeader
            display="flex"
            justifyContent="center"
            textAlign="center"
            pb="1px"
          >
            Descargar reporte
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            display="flex"
            justifyContent="center"
            flexDirection="column"
            textAlign="justify"
          >
            <FormControl my="5%">
              <FormLabel>Fecha inicio</FormLabel>
              <Input
                required
                type="date"
                onChange={handleChange}
                value={dates.date_start}
                name="date_start"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Fecha fin</FormLabel>
              <Input
                name="date_end"
                required
                type="date"
                value={dates.date_end}
                onChange={handleChange}
              />
            </FormControl>
            <Button
              isDisabled={isDisabled || loading}
              p={6}
              mx="auto"
              my="15%"
              variant="primary"
              onClick={handleClick}
            >
              {loading ? (
                <Loader text="Generando reporte" />
              ) : (
                'Descargar reporte'
              )}
            </Button>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalDownloadData;
