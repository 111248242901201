import { useState, useRef, useEffect } from 'react';
import { Button, HStack, Image, Textarea } from '@chakra-ui/react';
import EmojiPicker from '@emoji-mart/react';
import {
  sendVoluntarieMessage,
  sendUsuarieMessage,
} from '../../../services/Messages';
import { useSelector, useDispatch } from 'react-redux';
import { loadingActions } from '../../../store/Slices/loading';
import { logErrorEvent } from '../../../helpers/logAnalytics';
import ERROR_CATALOG from '../../../helpers/errorCatalog';
import { useLocation } from 'react-router-dom';

const ChatInputBar = ({ onError }) => {
  const emojiBox = useRef();
  const emojiPickerButton = useRef();
  const [inputCurrentUser, setInputCurrentUser] = useState('');
  const [showEmojis, setShowEmojis] = useState(false);
  const [hasError, setHasError] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const currentRoom = useSelector((state) => state.room.currentRoom);
  const currentUser = useSelector((state) => state.user);
  const isSendingMessage = useSelector(
    (state) => state.loading.isSendingMessage
  );

  const isUsuarie = location.pathname === '/usuaries/chat';

  const valueInputVoluntarie = (e) => {
    setInputCurrentUser(e.target.value);
  };

  const addEmoji = (e) => {
    const symbol = e.unified.split('-');
    const codesArray = [];
    symbol.forEach((element) => codesArray.push('0x' + element));
    const emoji = String.fromCodePoint(...codesArray);
    setInputCurrentUser(inputCurrentUser + emoji);
  };

  const handleEmojiButton = () => {
    setShowEmojis(!showEmojis);
  };

  const closeEmojiBox = (clickOutside = false) => {
    if (!showEmojis && clickOutside) return;
    const isEmojiboxClicked = emojiBox.current.contains(event.target);
    const isEmojiPickerButtonClicked = emojiPickerButton.current.contains(
      event.target
    );
    if (!isEmojiboxClicked && !isEmojiPickerButtonClicked) {
      setShowEmojis(false);
    }
  };

  const onSessionExpired = () => {
    logErrorEvent({
      message: 'Session expired',
      component_name: isUsuarie
        ? ERROR_CATALOG.USUARIES.SEND_MESSAGE
        : ERROR_CATALOG.VOLUNTARIES.SEND_MESSAGE,
    });
  };

  const handleMessageSubmit = async () => {
    if (!currentUser.uuid && !currentRoom._idUsuarie) {
      onSessionExpired();
      return;
    }
    dispatch(loadingActions.setSendingMessage(true));
    const message = {
      text: inputCurrentUser.replace(/\n/g, '. '),
      senderId: currentUser.uuid,
      roomId: currentRoom._idUsuarie,
      sessionCounter: currentRoom.sessionCounter,
      accessToken: currentUser.token,
      usuarieUuid: currentRoom.uuid,
    };
    setHasError(false);
    try {
      isUsuarie
        ? await sendUsuarieMessage(message)
        : await sendVoluntarieMessage(message);
    } catch (error) {
      logErrorEvent({
        message: error.message,
        component_name: isUsuarie
          ? ERROR_CATALOG.USUARIES.SEND_MESSAGE
          : ERROR_CATALOG.VOLUNTARIES.SEND_MESSAGE,
      });
      setHasError(true);
    } finally {
      setInputCurrentUser('');
      dispatch(loadingActions.setSendingMessage(false));
    }
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleMessageSubmit();
    }
  };

  const adjustTextareaRows = (event) => {
    const textarea = event.target;
    const lineHeight = parseInt(getComputedStyle(textarea).lineHeight, 10);
    const maxRows = 3;
    const currentRows = Math.floor(textarea.scrollHeight / lineHeight);
    const newRows = Math.min(currentRows, maxRows);
    textarea.rows = newRows;
  };

  useEffect(() => {
    if (!hasError) return;
    onError && onError();
  }, [hasError]);

  return (
    <HStack
      spacing={0}
      justifyContent="space-between"
      w="100%"
      h="100%"
      px="3"
      py="6"
      bg="white"
      borderColor="carbon.250"
    >
      <Button
        data-testid="addEmojiButton"
        variant={'ghost'}
        onClick={handleEmojiButton}
        ref={emojiPickerButton}
        p="0px !important"
        mr="8px !important"
        aria-label="Añadir emoji"
      >
        <Image
          src={'/icons/sentiment_satisfied.svg'}
          boxSize={'40px'}
        />
      </Button>
      {showEmojis && (
        <div
          ref={emojiBox}
          style={{ zIndex: 1, position: 'absolute', marginBottom: '525px' }}
          data-testid="emojiBox"
        >
          <EmojiPicker
            onEmojiSelect={addEmoji}
            onClickOutside={closeEmojiBox}
          />
        </div>
      )}

      <Textarea
        data-testid="inputChat"
        id="chatInput"
        resize="none"
        w="100%"
        minH="32px"
        rows="1"
        borderColor="#8E8E8E"
        focusBorderColor="#8E8E8E"
        borderRadius="9px"
        value={inputCurrentUser}
        isDisabled={isSendingMessage}
        onChange={(e) => {
          valueInputVoluntarie(e);
          adjustTextareaRows(e);
        }}
        onKeyDown={(e) => handleEnter(e)}
      />

      <Button
        data-testid="sendMessageButton"
        variant="primary"
        borderRadius={'50%'}
        w="10"
        h="10"
        p="0"
        ml="8px !important"
        onClick={handleMessageSubmit}
        disabled={!inputCurrentUser}
        aria-label="Enviar mensaje"
        isDisabled={isSendingMessage}
      >
        <Image
          src={'/icons/send.svg'}
          h="4"
          w="5"
        />
      </Button>
    </HStack>
  );
};

export default ChatInputBar;
