import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanels, TabPanel, Tabs } from '@chakra-ui/react';
import { fetchToggles } from '../../../../services/FirebaseRemoteConfig';
import UsuarieAttributes from '../../../molecules/UsuarieAttributes';
import UsuarieTags from '../../../molecules/UsuarieTags';
import {
  saveUsuarieAttributeInRoom,
  saveUsuarieTagsInRoom,
} from '../../../../services/Usuarie';

const UserDetailTabs = () => {
  const [showUsuarieTags, setShowUsuarieTags] = useState(false);
  const currentRoom = useSelector((state) => state.room.currentRoom);

  const handleSaveUsuarieAttribute = (text) => {
    return saveUsuarieAttributeInRoom(text, currentRoom.uuid);
  };

  const handleSaveUsuarieTags = (tags) => {
    return saveUsuarieTagsInRoom(tags, currentRoom.uuid);
  };
  useEffect(() => {
    fetchToggles().then((res) => {
      const showTags = res?.REACT_APP_SHOW_TAGS_VOLUNTARIE;
      if (!showTags) return;
      setShowUsuarieTags(showTags.asBoolean());
    });
  }, []);

  return (
    <Tabs
      height="60%"
      variant="enclosed"
      borderColor="carbon.400"
      color="mora.500"
    >
      <TabList
        height="10%"
        minHeight="fit-content"
      >
        <Tab
          data-testid="attributes-tab"
          borderRadius="0"
          borderColor="carbon.400"
          color="mora.500"
          fontWeight="700"
          w="110px"
        >
          Atributos
        </Tab>
        {showUsuarieTags && (
          <Tab
            data-testid="tags-tab"
            borderRadius="0"
            w="110px"
            borderColor="carbon.400"
            color="mora.500"
            fontWeight="700"
          >
            Tags
          </Tab>
        )}
      </TabList>
      <TabPanels height="90%">
        <TabPanel
          border="1px solid"
          borderColor="carbon.400"
          height="100%"
        >
          <UsuarieAttributes
            onSave={handleSaveUsuarieAttribute}
            room={currentRoom}
          />
        </TabPanel>
        {showUsuarieTags && (
          <TabPanel
            data-testid="tags-panel"
            h="100%"
            border="1px solid"
            borderColor="carbon.400"
          >
            <UsuarieTags
              onSave={handleSaveUsuarieTags}
              room={currentRoom}
            />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
};
export default UserDetailTabs;
