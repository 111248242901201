export const ACTIONS = {
  SET_LOADING: 'SET_LOADING',
  SET_ERROR: 'SET_ERROR',
  SET_DATA: 'SET_DATA',
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        data: [],
      };
    case ACTIONS.SET_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        errorDetails: action.payload,
      };
    case ACTIONS.SET_DATA:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
