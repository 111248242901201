import { extendTheme } from '@chakra-ui/react';
import '@fontsource/noto-sans/500.css';

const theme = extendTheme({
  styles: {
    global: {
      fonts: {
        heading: 'Noto Sans',
      },
      body: {
        fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        bg: 'carbon.0',
        h: '100vh',
      },
    },
  },
  colors: {
    primary: '#472980',
    text: '#000000',
    background: '#E5E5E5',
    mora: {
      400: '#7A57B2',
      500: '#472980',
      600: '#361D6E',
    },
    berry: {
      300: '#F27587',
      500: '#D61E5C',
      700: '#9A0F59',
    },
    sunny: {
      300: '#FAC455',
      500: '#F7A81E',
      600: '#D48715',
    },
    aqua: {
      400: '#5CE6C6',
      500: '#00AEA5',
      700: '#006D7D',
    },
    carbon: {
      0: '#FFFFFF',
      100: '#F3F3F3',
      200: '#E7E7E7',
      250: '#BEBEBE',
      300: '#B9B9B9',
      400: '#737373',
      500: '#161616',
    },
    leaf: {
      200: '#9EFAB5',
      500: '#12CE79',
      800: '#057766',
    },
    heart: {
      200: '#F5BBA3',
      500: '#D82729',
      800: '#7D0C2B',
    },
    sea: {
      200: '#9FD9FF',
      500: '#1180FF',
      800: '#053393',
    },
    gold: {
      100: '#FCD677',
      200: '#FCEA97',
      500: '#EAAC00',
      800: '#875800',
    },
    danger: '#D82729',
    success: '#12CE79',
  },
  textStyles: {
    xl_bold: {
      fontSize: '32px',
      fontWeight: '700',
      lineHeight: '38.4px',
      letterSpacing: '0.6px',
    },
    h1: {
      fontSize: '24px',
      fontWeight: '700',
      lineHeight: '26px',
    },
    h2: {
      fontSize: '20px',
      fontWeight: '600',
      lineHeight: '22px',
    },
    h3: {
      fontSize: '20px',
      fontWeight: '800',
      lineHeight: '22px',
    },
    h4: {
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '22px',
    },
    h5: {
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '25px',
    },
    subtitle: {
      fontSize: '22px',
      fontWeight: '400',
      lineHeight: '29.96px',
    },
    regular: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '19.2px',
    },
    body1: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '20px',
    },
    body2: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '18px',
    },
    button: {
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '16px',
    },
    link: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '16px',
    },
    small: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '22px',
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '28px',
        minHeight: '15px',
      },
      sizes: {
        xs: {
          h: '24px',
          fontSize: '12px',
          lineHeight: '16px',
        },
        sm: {
          h: '32px',
          fontSize: '14px',
          lineHeight: '20px',
        },
        md: {
          h: '40px',
          fontSize: '16px',
          lineHeight: '24px',
        },
        lg: {
          h: '48px',
          fontSize: '18px',
          lineHeight: '28px',
        },
      },
      variants: {
        primary: {
          bgColor: 'primary',
          borderRadius: '4px',
          color: 'carbon.0',
          _hover: {
            bgColor: 'mora.400',
            _disabled: {
              bgColor: 'carbon.300',
              opacity: 1,
            },
          },
          _disabled: {
            bgColor: 'carbon.300',
            opacity: 1,
          },
        },
        'primary-outline': {
          bgColor: 'none',
          borderRadius: '4px',
          border: '1.5px solid #472980',
          color: 'mora.500',
          _hover: {
            color: 'mora.400',
            border: '1.5px solid #7A57B2',
          },
          _disabled: {
            bgColor: 'carbon.300',
            opacity: 1,
          },
        },
        'chip-button': {
          bgColor: 'none',
          borderRadius: '4px',
          border: '1px solid #737373',
          color: 'carbon.400',
          minHeight: '46px',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '18px',
          _focus: {
            bgColor: 'mora.500',
            fontSize: '14px',
            color: 'white',
            textStyle: 'body1',
            borderColor: 'mora.500',
          },
        },
      },
    },
  },
});

export const buttonProps = {
  h: '3.125rem',
  color: '#8E8E8E',
  lineHeight: '1.125rem',
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: '400',
  border: '1px',
  borderColor: '#8E8E8E',
  opacity: '0.8',
};

export const buttonPropsClicked = {
  h: '3.125rem',
  backgroundColor: '#472980',
  lineHeight: '1.125rem',
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: '400',
  border: '1px',
  borderColor: '#A5A5A5',
  color: '#FFFFFF',
};

export const fichaButtonProps = {
  color: '#472980',
  lineHeight: '0.5',
  variant: 'outline',
  fontWeight: '400',
  border: '1px',
  borderColor: '#472980',
  opacity: '0.8',
};

export const fichaButtonPropsClicked = {
  backgroundColor: '#472980',
  lineHeight: '0.5',
  variant: 'outline',
  fontWeight: '400',
  border: '1px',
  borderColor: '#472980',
  color: '#FFFFFF',
};

export default theme;
