export const validateFields = (
  name,
  age,
  commune,
  gender,
  bioSex,
  sexualOrientation,
  acceptedTerms
) => {
  return (
    name.trim() !== '' &&
    age.trim() !== '' &&
    commune.trim() !== '' &&
    gender.trim() !== '' &&
    bioSex.trim() !== '' &&
    sexualOrientation.trim() !== '' &&
    acceptedTerms
  );
};
