import UsuarieLoginHeader from '../../../components/molecules/UsuarieLoginHeader';
import FormPhoneNumber from '../../../components/molecules/FormPhoneNumber/FormPhoneNumber';

import ButtonSendPhoneCode from '../../../components/atoms/ButtonSendPhoneCode';
import WithRecaptchaHandleV2 from '../../../components/molecules/WithRecaptchaHandleV2';

const UsuarieLoginPage = () => {
  return (
    <>
      <UsuarieLoginHeader />
      <FormPhoneNumber />
      {WithRecaptchaHandleV2(ButtonSendPhoneCode)}
    </>
  );
};

export default UsuarieLoginPage;
