import { app } from '../firebaseConfig';
import togglesConfig from '../utils/featureToggle';
import {
  setPersistence,
  signInWithEmailAndPassword,
  browserSessionPersistence,
} from 'firebase/auth';

const authConfig = () => {
  const auth = app.auth();
  const disabledRecaptcha = localStorage.getItem('disabledRecaptcha');
  const { shouldDisabledRecaptcha } = togglesConfig({
    disabledRecaptcha,
  });
  auth.settings.appVerificationDisabledForTesting = shouldDisabledRecaptcha;
  return auth;
};

export const auth = authConfig();

export const checkAuthState = (cb) => {
  return auth.onAuthStateChanged((user) => {
    cb(user);
  });
};

const loginVoluntarie = (email, password) => {
  return setPersistence(auth, browserSessionPersistence)
    .then(() => {
      return signInWithEmailAndPassword(auth, email, password);
    })
    .catch(() => {
      // Handle Errors here.
      // const errorCode = error.code;
      // const errorMessage = error.message;
    });
};

export const logoutAuth = async () => {
  await auth.signOut();
};

const FirebaseAuthService = {
  loginVoluntarie,
  logoutAuth,
  auth,
};

export default FirebaseAuthService;
