import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useRequestMessages from '../../../hooks/useRequestMessages';
import { getRoomByUsuarieId, getRoomSnapshot } from '../../../services/Rooms';
import { roomActions } from '../../../store/Slices/room';
import ChatWindow from '../../molecules/ChatWindow';
import { checkAuthState } from '../../../services/FirebaseAuthService';
import { userActions } from '../../../store/Slices/users';

const UsuarieChat = () => {
  const currentRoom = useSelector((state) => state.room.currentRoom);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = {
    idRoom: currentRoom._idUsuarie,
    accessToken: user.token,
    userType: 'usuarie',
  };

  const {
    isLoading: isLoadingMessages,
    hasError,
    data: usuarieChats,
  } = useRequestMessages(params);

  useEffect(() => {
    const idRoom = currentRoom._idUsuarie;
    if (idRoom) {
      getRoomSnapshot(currentRoom.uuid, (room) => {
        room && dispatch(roomActions.setRoom(room));
      });
    }
  }, [currentRoom.uuid]);

  useEffect(() => {
    let unsubscribe;
    const doAsync = async () => {
      unsubscribe = checkAuthState(async (user) => {
        const profile =
          user && (await getRoomByUsuarieId(user.auth.currentUser.uid));
        if (user && profile) {
          dispatch(
            userActions.setUser({
              userType: 'usuarie',
              uuid: user.auth.currentUser.uid,
              token: user.auth.currentUser.accessToken,
              displayName: profile ? profile.name : 'Usuarie',
            })
          );
          if (profile) {
            dispatch(roomActions.setRoom(profile));
          }
        } else {
          navigate('/usuaries/auth');
        }
      });
    };
    doAsync();
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <ChatWindow
      messages={usuarieChats}
      hasError={hasError}
      showDate={false}
      isLoadingMessages={isLoadingMessages}
    />
  );
};

export default UsuarieChat;
