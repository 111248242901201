import { Button, Text } from '@chakra-ui/react';

const ButtonWithLeftIcon = ({ onClick, text, iconSource, datatestid }) => {
  return (
    <Button
      width="auto"
      px="24px"
      py="12px"
      variant="link"
      data-testid={datatestid}
      color="mora.500"
      display="flex"
      alignItems="center"
      leftIcon={iconSource}
      _hover={{ bg: 'carbon.100' }}
      onClick={onClick}
    >
      <Text>{text}</Text>
    </Button>
  );
};
export default ButtonWithLeftIcon;
