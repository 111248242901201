import { useDispatch, useSelector } from 'react-redux';
import { Flex } from '@chakra-ui/react';
import IconPhone from '../../atoms/IconPhone';
import ContainerCenterContent from '../../atoms/ContainerCenterContent';
import InputPhoneNumber from '../../atoms/InputPhoneNumber';
import TextPhone from '../../atoms/TextPhone';
import { isNumeric } from '../../../utils/numberValidation';
import { phoneNumberActions } from '../../../store/Slices/phoneNumber';
import { pinSentActions } from '../../../store/Slices/pinSent';

const FormPhoneNumber = () => {
  const dispatch = useDispatch();

  const phoneNumber = useSelector((state) => state.phoneNumber.number);

  const handlePhoneChange = (e) => {
    dispatch(pinSentActions.setPinNotSent());
    const inputValue = e.target.value;
    if (inputValue.length === 8 && isNumeric(inputValue)) {
      dispatch(phoneNumberActions.setValidPhoneNumber(inputValue));
    } else {
      dispatch(phoneNumberActions.setInvalidPhoneNumber(inputValue));
    }
  };

  return (
    <ContainerCenterContent>
      <Flex
        direction="row"
        mt="1.875rem"
        alignItems="center"
      >
        <IconPhone />
        <TextPhone />
        <InputPhoneNumber
          onChange={handlePhoneChange}
          value={phoneNumber}
        />
      </Flex>
    </ContainerCenterContent>
  );
};

export default FormPhoneNumber;
