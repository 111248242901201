import { createSlice } from '@reduxjs/toolkit';

const initialPinSetState = { isPinSent: false, usuariePin: '' };
const pinSentSlice = createSlice({
  name: 'pinSent',
  initialState: initialPinSetState,
  reducers: {
    setPinSent(state) {
      state.isPinSent = true;
      state.usuariePin = '';
    },
    setPinNotSent(state) {
      state.isPinSent = false;
    },
    setUsuariePin(state, action) {
      state.usuariePin = action.payload;
    },
  },
});

export const pinSentActions = pinSentSlice.actions;
export const pinSentReducers = pinSentSlice.reducer;
export default pinSentSlice;
