import { Flex } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ErrorAlert from '../../../molecules/ErrorAlert';
import { getRoomByUsuarieId } from '../../../../services/Rooms';
import { roomActions } from '../../../../store/Slices/room';
import { userActions } from '../../../../store/Slices/users';
import PimFormTitle from '../../../atoms/PimFormTitle';
import PinInputUsuarie from '../../../atoms/PinInputUsuarie';
import PinButtonLogin from '../../../atoms/PinButtonLogin';
import PinBottom from '../../../molecules/PinBottom';
import { logErrorEvent } from '../../../../helpers/logAnalytics';
import ERROR_CATALOG from '../../../../helpers/errorCatalog';
import PropTypes from 'prop-types';

const PhoneValidation = ({ result, userPhone }) => {
  const [userPin, setUserPin] = useState('');
  const [validPin, setValidPin] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePinChange = ({ isValid, value }) => {
    if (isValid) {
      setUserPin(value);
    }
    setValidPin(isValid);
  };

  const verifyOtpCode = async (e) => {
    e.preventDefault();
    try {
      const afterConfirm = await result.confirm(userPin);
      const profile = await getRoomByUsuarieId(afterConfirm.user.uid);

      dispatch(
        userActions.setUser({
          userType: 'usuarie',
          uuid: afterConfirm.user.uid,
          token: afterConfirm.user.accessToken,
          displayName: profile ? profile.name : 'Usuarie',
        })
      );

      localStorage.setItem('phoneNumber', userPhone);

      if (!profile) {
        return navigate('/usuaries/form');
      }
      dispatch(roomActions.setRoom(profile));
      return navigate('/usuaries/chat');
    } catch (error) {
      logErrorEvent({
        message: error.message,
        component_name: ERROR_CATALOG.USUARIES.VERIFY_CODE,
      });
      setErrorAlert(true);
      console.error(error);
    }
  };

  const changePhoneNumber = () => {
    console.log('change phone number');
  };

  const resendCode = () => {
    console.log('resend code');
  };

  return (
    <Flex
      w="100%"
      direction="column"
      mt="4.625rem"
      mb="4.625rem"
      alignItems="center"
      boxShadow="md"
    >
      <PimFormTitle />
      <PinInputUsuarie onChange={handlePinChange} />
      <PinButtonLogin
        onClick={verifyOtpCode}
        validPin={validPin}
      />
      <PinBottom
        changePhoneNumber={changePhoneNumber}
        resendCode={resendCode}
      />
      {errorAlert && (
        <ErrorAlert message="El código de verificación es incorrecto. Inténtalo nuevamente." />
      )}
    </Flex>
  );
};

PhoneValidation.propTypes = {
  result: PropTypes.shape({
    confirm: PropTypes.func.isRequired,
  }).isRequired,
  userPhone: PropTypes.string.isRequired,
};

export default PhoneValidation;
