import { Text, Box, OrderedList, ListItem, Link } from '@chakra-ui/react';

const TermsAndConditionsPage = () => {
  return (
    <Box>
      <Text
        fontSize="xl"
        pb="3%"
        fontWeight="bold"
      >
        {' '}
        I. Introducción
      </Text>
      <Text>
        La Fundación Todo Mejora Chile es una institución sin fines de lucro que
        trabaja para entregar orientación, contención emocional y derivaciones a
        niñxs y jóvenes que sufren experiencias de polivictimización por su
        orientación sexual, identidad o expresión de género, real o percibida, a
        través de sus canales de comunicación. Para ello dispone de un equipo de
        voluntarixs preparadxs para realizar acompañamiento a personas
        pertenecientes a la comunidad gay, lesbiana, bisexual, transexual y
        queer (LGBTQIA+) por medio de servicios de mensajería dispuestos en las
        distintas plataformas disponibles. Para lograr la consecución de estos
        objetivos, la Fundación Todo Mejora Chile ha dispuesto los siguientes
        Términos y Condiciones de Uso, aplicables a cualquier persona que acceda
        a las distintas plataformas en las que se encuentran disponibles sus
        servicios, y material informativo. Los presentes Términos y Condiciones
        de Uso (“Términos y Condiciones”) regulan la relación entre, por un
        lado, la Fundación Todo Mejora Chile, afiliada de la organización sin
        fines de lucro ItGetsBetter, (en adelante, “Todo Mejora” o la
        “Fundación”) Rol Único Tributario N°65.064.672-K, domiciliada en General
        Bustamante #26 y debidamente representada por Patricio Guillermo Jiménez
        Trejo, Soltero, Abogado, R.U.T 10.927.042-3; y, por el otro, lxs
        personas que acceden al contenido provisto por la Fundación a través de
        su sitio web{' '}
        <Link
          color="blue"
          href="https://www.todomejora.org"
          isExternal
        >
          www.todomejora.org
        </Link>{' '}
        (“Sitio Web”), por correo electrónico, su aplicación móvil (“App Hora
        Segura” o “App”), plataformas de redes sociales, o por cualquier otro
        medio (en adelante las “Plataformas” y lxs “Usuarixs”, respectivamente).
        Dentro de lxs Usuarixs, existen ciertas personas que utilizan los
        canales de comunicación para solicitar apoyo, guía u orientación (lxs
        “Usuarixs Orientadxs”) el que es provisto mediante dispositivos
        tecnológicos por voluntarixs capacitadxs (lxs “Voluntarixs”) a través de
        la línea de ayuda de del Programa Hora Segura y/o a través de la App (el
        “Servicio”). En este último caso será necesario crearse un nombre de
        usuario y contraseña. Lxs Voluntarixs podrán ser personas mayores de 23
        años, estudiantes de último año o egresados de carreras de las ciencias
        sociales, ciencias de la salud y ciencias de la educación, capacitados
        en primeros auxilios psicológicos y contención en crisis, con
        sensibilidad hacia las problemáticas de salud mental. Lxs voluntarixs
        han sido capacitados por la Fundación para entregar apoyo y soporte,
        detectar este tipo de comportamientos y comunicarlos con el objetivo de
        proteger el interés superior de los niños, niñas y adolescentes. Todo
        Mejora reconoce que no cuenta con los medios o certificaciones
        necesarias para brindar asesoramiento, diagnóstico o tratamiento
        psicológico, psiquiátrico o farmacéutico a sus Usuarixs. Forman parte
        integrante de estos Términos y Condiciones, los Términos de Uso de
        ItGetsBetter disponibles en{' '}
        <Link
          color="blue"
          href="https://itgetsbetter.org/terms-of-use/"
          isExternal
        >
          https://itgetsbetter.org/terms-of-use
        </Link>
        . Asimismo, forma parte integrante de estos Términos y Condiciones, la
        Política de Privacidad de Todo Mejora, disponible en{' '}
        <Link
          color="blue"
          href="https://todomejora.org/politica-de-privacidad-todo-mejora/"
          isExternal
        >
          https://todomejora.org/politica-de-privacidad-todo-mejora
        </Link>
        . Ante contradicciones entre los Términos de Uso locales y aquellos de
        ItGestsBetter, primarán los primeros. Los Términos y Condiciones se
        entenderán incorporados y serán aplicables a todas las comunicaciones de
        lxs Usuarixs con Todo Mejora por cualquiera de los Medios de Contacto.
        Por la aceptación de estos Términos y Condiciones, cada Usuarix declara
        haber leído y comprendido las condiciones establecidas en estos Términos
        y Condiciones, así como la Política de Privacidad de la Fundación. Se
        entenderá que lxs Usuarix aceptan estos Términos y Condiciones al
        utilizar las Plataformas o contactar por cualquier medio a la Fundación.
        Cuando lxs Usuarixs Orientadxs soliciten apoyo, guía u orientación por
        sufrir experiencias de polivictimización por su orientación sexual,
        identidad o expresión de género, real o percibida, previo a entregarles
        asistencia se les solicitará aceptar expresamente estos Términos de Uso
        y la Política de Privacidad.{' '}
      </Text>
      <Text
        fontSize="xl"
        py="3%"
        fontWeight="bold"
      >
        {' '}
        II. Capacidad{' '}
      </Text>
      <Text>
        Lxs Usuarixs Orientadxs podrán ser personas entre 13 años y 29 años de
        edad inclusive, domiciliados en Chile. Al utilizar los Servicios, xl
        Usuarix declara cumplir con la edad requerida para utilizar los
        Servicios, así como los demás requisitos que se señalan más adelante en
        estos Términos y Condiciones.
      </Text>
      <Text
        fontSize="xl"
        py="3%"
        fontWeight="bold"
      >
        {' '}
        III. Modificación de los Términos y Condiciones{' '}
      </Text>
      <Text>
        Estos Términos y Condiciones podrán ser modificados por Todo Mejora
        cuando lo considere oportuno debido a cambios en la legislación o bien
        en la forma de direccionar su funcionamiento. Los cambios a estos
        Términos y Condiciones serán debidamente publicados por la Fundación a
        través de su Sitio Web. En caso de cambios que afecten sustantivamente
        la prestación de los Servicios, Todo Mejora solicitará nuevamente el
        consentimiento del Usuarix. Lxs Usuarios podrán configurar su cuenta en
        las aplicaciones móviles de la Fundación, de manera que le notifique
        cualquier cambio en los Términos y Condiciones a su correo electrónico
        registrado. En caso de que algún Usuarix Orientadx no acepte las
        modificaciones efectuadas a los Términos y Condiciones, no podrá
        utilizar los Servicios.{' '}
      </Text>
      <Text
        fontSize="xl"
        py="3%"
        fontWeight="bold"
      >
        {' '}
        IV. Términos y Reglas de Conducta{' '}
      </Text>
      <Text>
        Los siguientes Términos y Reglas de Conducta (“Términos y Reglas de
        Conducta”) deberán ser observados por lxs Usuarixs, al comunicarse
        mediante las Plataformas. La no observancia de estos Términos y Reglas
        de Conducta facultará a Todo Mejora a suspender o cancelar la cuenta de
        lx Usuarix. En caso de ser necesario, Todo Mejora podrá incluso iniciar
        acciones legales en contra de quienes hagan mal uso de sus cuentas.
      </Text>
      <OrderedList
        pl="5%"
        py="2%"
      >
        <ListItem>
          Al acceder a las Plataformas de Todo Mejora, y de forma previa a la
          prestación de los Servicios por la Fundación, lxs Usuarixs deberán
          ingresar datos de identificación y de contacto, según lo establecido
          en la Política de Privacidad de Todo Mejora. Es responsabilidad de lx
          Usuarix aportar datos verídica, exacta y completa.{' '}
        </ListItem>
        <ListItem>
          Fundación Todo Mejora otorga a lxs Usuarixs una licencia limitada en
          los términos señalados en la cláusula VII de los presentes Términos de
          Uso que regirá la forma en que deberán utilizarse los contenidos
          sujetos a protección de propiedad intelectual o industrial puesto a
          disposición.
        </ListItem>
        <ListItem>
          Ningúnx Usuarix podrá publicar comentarios o material audiovisual en
          cualquiera de las Plataformas de Todo Mejora que vulneren derechos de
          terceros, o contener información falsa, ofensiva, discriminatoria,
          LGBTQIA+-fóbicas, contrarias a la ley o a estos Términos y
          Condiciones.
        </ListItem>
        <ListItem>
          Ningún Usuarix podrá realizar acciones o actividades que impida,
          restrinja o dificulte la puesta a disposición del contenido de la
          Fundación y/o la prestación de Servicios, o la consecución de sus
          fines en general.
        </ListItem>
        <ListItem>
          {' '}
          Ningún usuario podrá participar en cualquier actividad contraria a las
          leyes chilenas, especialmente la Ley N°20.609 que Establece Medidas
          Contra la Discriminación, la Ley N°21.120 que reconoce y da protección
          al derecho a la identidad de género, o cualquier otra norma o tratado
          referido a la protección de las diversidades sexuales.
        </ListItem>
      </OrderedList>
      <Text
        fontSize="xl"
        pb="3%"
        fontWeight="bold"
      >
        V. Alcance de los servicios de Todo Mejora
      </Text>
      <Text>
        Los contenidos que publica Todo Mejora en cualquiera de las Plataformas,
        como textos, gráficos, imágenes, información y cualquier otro contenido
        han sido elaborados por la Fundación, o por terceros, empleando las
        mejores prácticas de estudio, con fines meramente informativos. Lxs
        Voluntarixs de la Fundación desempeñan una labor de apoyo, contención,
        orientación y derivación oportuna a la red de centros de salud mental
        aliados a la Fundación Todo Mejora. En esta línea, las comunicaciones
        entre unx Usuarix y la Fundación, asistidas por unx voluntarix, tienen
        como objeto responder las dudas de lxs Usuarixs, sus familias y entorno
        protector, así como prestar servicios de contención en casos de crisis.
        En el desempeño de su labor, es posible que lxs Voluntarixs consideren
        que lxs Usuarixs Orientadxs requieran de asistencia psicológica o
        pisquiátrica y/o acceso a tratamientos hormonales y ginecologxs
        especialistas en salud trans y que sea necesaria la derivación a un
        centro aliado de la Fundación, incluyendo la presencia de acoso o
        maltrato, así como la existencia de comportamientos que puedan ser
        considerados como riesgosos para la salud e integridad de lx Usuarix. La
        Fundación siempre requerirá del consentimiento expreso de lxs Usuarixs
        Orientadxs previo a que se comuniquen sus datos a estas instituciones.
        Asimismo, también es posible que lxs Voluntarixs se percaten de
        situaciones de posibles riesgos psicológicos graves que requieran de
        intervención profesional especializada o de las autoridades
        correspondientes, entre ellos, (i) la existencia de conductas suicidas,
        (ii) la comisión de delitos, (iii) la vulneración de derechos de menores
        de edad En caso de emergencias médicas o urgencias relacionadas con
        intentos de suicidio, lxs Usuarixs podrán contactar a organismos
        expertos en manejo de crisis y tratamientos psicológicos y
        psiquiátricos, que cuentan con herramientas para su atención inmediata.
        Especialmente, el Servicio Nacional Salud Responde, a través del número
        telefónico 600 360 7777, opción N°3; el servicio Fono Niños, a través
        del número telefónico 147, o Fono Familia, a través del número
        telefónico 149, todas desde cualquier teléfono móvil o fijo. En caso de
        que existan develación de vulneración de derechos, la Fundación podrá
        notificar a la Defensoría de la Niñez. Los materiales o contenidos que
        Todo Mejora publique en sus Plataformas, así como las conversaciones con
        cualquiera de sus voluntarixs, especialmente aquellas sostenidas por el
        chat del Programa Hora Segura, jamás serán un reemplazo o sustituto de
        un asesoramiento, diagnóstico, tratamiento o consulta médica por un
        psiquiatra, psicólogx, o cualquier otrx profesional de la salud. La
        Fundación no se hará responsable frente a casos de complejidad médica o
        legal que se pudieran presentar en las conversaciones.
      </Text>
      <Text
        fontSize="xl"
        py="3%"
        fontWeight="bold"
      >
        {' '}
        VI. Privacidad
      </Text>
      <Text>
        El acceso, almacenamiento y tratamiento de datos personales por la
        Fundación, a través de las Plataformas y por la prestación de sus
        Servicios, así como su protección, se encuentra regulado en la Política
        de Privacidad de Todo Mejora. Por la aceptación de estos Términos y
        Condiciones, lxs Usuarixs declaran haber leído, entendido y aceptado la
        Política de Privacidad de Todo Mejora, dando su consentimiento para la
        recolección, almacenamiento y tratamiento de sus datos personales de
        conformidad con los términos ahí establecidos.
      </Text>
      <Text
        fontSize="xl"
        py="3%"
        fontWeight="bold"
      >
        {' '}
        VII. Repsonsabilidad
      </Text>
      <Text>
        Por este acuerdo, aceptas que Todo Mejora, en ningún caso, responderá
        por:{' '}
      </Text>
      <OrderedList
        pl="5%"
        py="2%"
      >
        <ListItem type="a">
          La utilización indebida que Usuarixs o visitantes de cualquiera de las
          Plataformas de Todo Mejora puedan hacer de los materiales exhibidos,
          de los derechos de propiedad industrial y de los derechos de propiedad
          intelectual.
        </ListItem>
        <ListItem type="a">
          El acceso y uso de los Servicios de Todo Mejora, o a los contenidos
          publicados por Todo Mejora en sus Plataformas, por personas que no
          cumplen con la edad requerida por estos Términos y Condiciones.
        </ListItem>
        <ListItem type="a">
          La información de Todo Mejora que se encuentre en otros sitios
          distintos a las plataformas que Todo Mejora administra.
        </ListItem>
      </OrderedList>
      <Text>
        Lxs Usuarixs reconocen que los Servicios prestados por medio de las
        Plataformas de la Fundación no son infalibles. Por lo anterior, Todo
        Mejora no se hará responsable por daños, perjuicios o pérdidas que se
        produzcan por fallas en el sistema, en el servidor o en internet.
        Tampoco será responsable por virus que pudieran infectar a los equipos
        de lxs Usuarixs como consecuencia del acceso, uso o visita al sitio web
        o a raíz de cualquier transferencia de datos, archivos, imágenes,
        textos, o contenido audiovisual en el mismo. Lxs Usuarixs no podrán
        imputar responsabilidad alguna a Todo Mejora ni exigir pago por lucro
        cesante, en virtud de perjuicios resultantes de dificultades técnicas o
        fallas en los sistemas o en internet. Todo Mejora no garantiza el acceso
        y uso continuado o ininterrumpido a las Plataformas. Los Servicios
        podrían no estar disponibles debido a dificultades técnicas o fallas de
        internet, o por cualquier otra circunstancia ajena y no imputable a Todo
        Mejora. Sin perjuicio de lo anterior, la Fundación siempre procurará
        mantener los Servicios disponibles para responder a los requerimientos
        de lxs Usuarixs y, en caso de producirse alguna falla, restablecer el
        servicio tan pronto como sea posible sin que por ello pueda reclamársele
        algún tipo de responsabilidad. Todo Mejora no será responsable por
        ningún error u omisión de contenidos en su sitio web o aplicaciones.
        Todo Mejora es responsable de proporcionar soporte y mantención
        únicamente respecto de sus propios sitios y aplicaciones. Todo Mejora,
        sus directorxs, empleadxs, afiliadxs, agentes, contratistas,
        licenciantes, socixs o especialistas, no serán responsables por ningún
        daño derivado del uso de sus Plataformas, salvo que provenga de la
        negligencia (culpa lata o grave) de su equipo, o hubiese sigo realizado
        con dolo por parte de éste.{' '}
      </Text>
      <Text
        fontSize="xl"
        py="3%"
        fontWeight="bold"
      >
        {' '}
        VIII. Propiedad intelectual{' '}
      </Text>
      <Text>
        Todos los contenidos incluidos en las plataformas de Todo Mejora, como
        textos, material gráfico, logotipos, íconos de botones, códigos fuente,
        imágenes, audio clips, descargas digitales y compilaciones de datos,
        encabezados de páginas, frases publicitarias, textos escritos y nombres
        de servicios incluidos en este sitio son marcas, creaciones o imágenes
        de propiedad de Todo Mejora en Chile y en otros países o de sus
        proveedores de contenidos, y están protegidos por las leyes chilenas e
        internacionales sobre propiedad industrial e intelectual según
        corresponda. Por medio de estos Términos de Uso, Todo Mejora entrega una
        licencia no exclusiva, intransferible, no susceptible de cesión y
        revocable que les permite consultar y descargar, de forma temporal, una
        copia del contenido de la página para uso personal y/o académico. La
        autorización excluye el uso comercial, la comunicación pública o la
        adaptación del contenido, sin el permiso previo por escrito de la
        Fundación. En cualquier caso, debe siempre incluirse una cita o
        reconocimiento a la autoría de la obra cuando ésta sea citada o
        utilizada como fuente de información.
      </Text>
      <Text
        fontSize="xl"
        py="3%"
        fontWeight="bold"
      >
        {' '}
        IX. Legislación aplicable y solución de controversias{' '}
      </Text>
      <Text>
        Estos Términos y Condiciones fueron elaborados y se regirán de acuerdo
        con las leyes de la República de Chile. Todas las dificultades
        controversias que se susciten entre las Partes con motivo del
        incumplimiento, interpretación, y cualquier otra dificultad o disputa
        que se derive del de estos Términos y Condiciones, de sus
        modificaciones, documentos integrantes o cualquier otra causa será
        sometida a los Tribunales Ordinarios de Justicia.
      </Text>
      <Text
        fontSize="xl"
        py="3%"
        fontWeight="bold"
      >
        {' '}
        X. Comunicaciones{' '}
      </Text>
      <Text>
        {' '}
        En caso de que cualquier persona tenga una consulta o reclamo sobre las
        Plataformas, podrá comunicarse con la Fundación a través del correo
        electrónico{' '}
        <Link
          color="blue"
          href="mailto:contacto@todomejora.org"
          isExternal
        >
          contacto@todomejora.org
        </Link>
        .
      </Text>
    </Box>
  );
};

export default TermsAndConditionsPage;
