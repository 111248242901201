import { Grid, GridItem } from '@chakra-ui/react';
import TMLinkButton from '../atoms/TMLinkButton';

const SHOW_VALIDATION_OPTIONS = false;

const PinBottom = ({ changePhoneNumber, resendCode }) => {
  return (
    <Grid
      templateColumns="repeat(2, 1fr)"
      mt="1.75rem"
      mb="1.75rem"
    >
      {SHOW_VALIDATION_OPTIONS && (
        <>
          <GridItem w="100%">
            <TMLinkButton onClick={changePhoneNumber}>
              Cambiar número
            </TMLinkButton>
          </GridItem>
          <GridItem w="100%">
            <TMLinkButton onClick={resendCode}>
              No recibí el código
            </TMLinkButton>
          </GridItem>
        </>
      )}
    </Grid>
  );
};

export default PinBottom;
