import { Text } from '@chakra-ui/react';
const TMTitleLoginUsuarie = ({ children }) => {
  return (
    <Text
      color="mora.500"
      lineHeight="2.125rem"
      textStyle="h2"
    >
      {children}
    </Text>
  );
};
export default TMTitleLoginUsuarie;
